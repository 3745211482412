import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { MarkupDisplayType } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { chartColorUnassigned } from '../../Charts/ChartsD3/ChartsPieGraph/utils';
import ContingencyBar, {
  getGraphNumbers,
} from '../../ContingencyReport/ContingencyReport/BarGraph/ContingencyReportBarGraph';
import { ContingencyReportType } from '../../ContingencyReport/ContingencyReport/ContingencyReportUtils';
import { processContingencySummary } from '../../ContingencyReport/ContingencyReport/SummaryReport/ContingencySummaryReportUtils';
import { useContingencyReportQuery } from '../../ContingencyReport/hooks/useContingencyReportQuery';
import { Tooltip } from '../../scales';
import CAndALegend from '../Charts/Legends/CAndALegend';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import Section from '../InsightsProject/Section';
import { getCandAPieCharData } from '../ProjectsTables/utils';
import ContingenciesPieToolTip from '../ToolTips/ContingenciesPieToolTip';
import HelpTooltip from '../ToolTips/HelpTooltip';

import SectionTitle from './SectionTitle';

type Props = { project: InsightsProject };

export default function ProjectCAndATable(props: Props) {
  const { project } = props;
  const milestones = props.project?.milestones || [];
  const activeMilestoneID = milestones.find((m) => m.active)?.id;
  const projectID = project.id;
  const milestoneID = activeMilestoneID;
  const mostRecentData =
    project.contingenciesTimeseries.length > 0
      ? project.contingenciesTimeseries[project.contingenciesTimeseries.length - 1]
      : null;
  const { caTotalPct, pieChartData } = getCandAPieCharData(mostRecentData, project);

  const pieChart = (
    <Tooltip
      content={
        <div>
          <ContingenciesPieToolTip
            currentContingencyData={mostRecentData}
            estimateTotal={project.estimate || 1}
          />
        </div>
      }
    >
      <div className="m-10">
        <ChartsPieGraph
          centerLabel={{
            label: '',
            sublabel: `${String(Math.floor(caTotalPct))}%`,
          }}
          chartSize={{
            diameter: 170,
            insideDiameter: 120,
          }}
          colors={[
            'var(--colors-theme-bauhaus-marcelbreuer)',
            'var(--colors-theme-bauhaus-laszlo)',
            'var(--colors-item-status-not-applicable-tint)',
          ]}
          data={pieChartData}
          unassignedColor={chartColorUnassigned}
        />
      </div>
    </Tooltip>
  );

  const { data: reportData, loading: loadingReport } = useContingencyReportQuery(
    projectID,
    milestoneID
  );
  const activeMilestoneReport = reportData?.contingencyReport ?? [];

  const allContingency = activeMilestoneReport.filter(
    (contingency) => contingency.displayType === MarkupDisplayType.CONTINGENCY
  );
  const allAllowance = activeMilestoneReport.filter(
    (contingency) => contingency.displayType === MarkupDisplayType.ALLOWANCE
  );

  const disableContingency = allContingency.length === 0;
  const disableAllowance = allAllowance.length === 0;

  const cBarTable = !disableContingency ? (
    <div className="mt-3 flex flex-col gap-5">
      <CAndALegend />
      <table className="h-fit w-[465px] table-auto">
        <tbody>
          {allContingency.map((contingency) => {
            const { starting, used, drawn, pending, name } = processContingencySummary(
              contingency,
              ContingencyReportType.ACCEPTED_AND_PENDING
            );
            return (
              <CAndARow
                key={contingency.contingencyID}
                drawn={drawn}
                name={name}
                pending={pending}
                starting={starting}
                used={used}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;

  const aBarTable = !disableAllowance ? (
    <div className="mt-3 flex flex-col gap-5">
      <CAndALegend isA />
      <table className="h-fit w-[465px] table-auto">
        <tbody>
          {allAllowance.map((allowance) => {
            const { starting, used, drawn, pending, name } = processContingencySummary(
              allowance,
              ContingencyReportType.ACCEPTED_AND_PENDING
            );
            return (
              <CAndARow
                key={allowance.contingencyID}
                drawn={drawn}
                name={name}
                pending={pending}
                starting={starting}
                used={used}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;

  const to = {
    pathname: generateSharedPath(JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT, {
      projectId: projectID,
      milestoneId: milestoneID,
    }),
  };

  if (loadingReport) return null;

  return (
    <div className="flex gap-7">
      <Section
        title={
          <div className="flex items-center gap-1">
            <div className="text-type type-heading3">% of Estimate</div>
            <HelpTooltip tooltipContent="A breakdown of the current standing of individual contingencies and allowances." />
          </div>
        }
      >
        {pieChart}
      </Section>
      <Section
        title={
          <SectionTitle
            count={allContingency.length}
            entityColor="bg-entities-contingencies"
            linkText="View draw report"
            title="Contingencies"
            to={to}
          />
        }
      >
        {cBarTable}
      </Section>
      <Section
        title={
          <SectionTitle
            count={allAllowance.length}
            entityColor="bg-entities-allowances"
            linkText="View draw report"
            title="Allowances"
            to={to}
          />
        }
      >
        {aBarTable}
      </Section>
    </div>
  );
}

type CAndABarProps = {
  starting: number;
  used: number;
  drawn: number;
  name: string;
  pending: number;
  overdrawLimit?: number;
};

export function CAndARow(props: CAndABarProps) {
  const { name, starting, used, drawn, pending, overdrawLimit = 0.25 } = props;
  const { drawPercentage, totalGraphAmount, remainingAmount } = getGraphNumbers(
    starting,
    used,
    drawn,
    pending,
    overdrawLimit
  );
  const format = {
    short: true,
  };
  const remainingToTotal = `${formatCost(
    // Remaining
    remainingAmount,
    format
  )}/${formatCost(
    // Total
    totalGraphAmount,
    format
  )}`;
  return (
    <tr className="h-10">
      <td className="w-[300px] text-right">
        <div className="text-left type-body2">{name}</div>
        <ContingencyBar
          drawn={0}
          graphWidth={300}
          overdrawLimit={overdrawLimit}
          pending={pending}
          percentageDisabled
          starting={starting}
          used={used}
        />
      </td>
      <td className="pb-1 text-right align-bottom">
        <div className="type-body2">{drawPercentage}</div>
      </td>
      <td className="pb-1 text-right align-bottom">
        <div className="text-type-muted type-body2">{remainingToTotal}</div>
      </td>
    </tr>
  );
}
