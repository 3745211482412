import { Link } from 'react-router-dom';

import { BusinessOutlined, ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { COMPANY_DASHBOARD } from '../../../constants';
import { ImageDimension } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { fromNow } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Chips } from '../../ForecastingRoute/common/Chips';
import { LongRightIcon } from '../../Milestone/MilestoneDetails/MilestoneDetailsNav/MilestoneDetailsNavIcons';
import { Button, Chip } from '../../scales';
import Thumbnail from '../../shared-widgets/Thumbnail/Thumbnail';
import { InsightsProject as InsightsProjectType } from '../hooks/useInsightsProjectsQuery';

import InsightsProject from './InsightsProject';
import { ProjectCompanyList } from './ProjectCompanyList';

// TODO: make sure that it is used of the InsightsProjectRoute
const InsightsProjectDetails = (props: { project: InsightsProjectType }) => {
  // TODO: fetch project here or in InsightsProjectRoute

  const chips = (
    <Chips>
      <Chip
        data-cy="project-location"
        icon={<LocationOnOutlined />}
        text={props.project.location}
      />
      <Chip data-cy="project-type" icon={<BusinessOutlined />} text={props.project.type} />
      <Chip data-cy="project-status" text={props.project.status} />
      <Chip data-cy="project-status" icon={<LocationOnOutlined />} text={props.project.location} />
      {props.project.orgNodes.map(
        (node) =>
          node && (
            <Chip
              key={node.id}
              data-cy="project-orgs"
              icon={
                <div>
                  <img alt="organization icon" src="/img/OrganizationTree.svg" width={12} />
                </div>
              }
              text={node.name}
            />
          )
      )}
    </Chips>
  );

  const alertChips = (
    <div className="flex gap-2">
      {props.project.activeAlerts.map((a) => (
        <Chip
          key={a.alertID}
          backgroundColor="#FDF0F0"
          icon={<ErrorOutline />}
          text={a.description}
        />
      ))}
    </div>
  );

  return (
    <div className="flex max-h-full max-w-full grow flex-col justify-between gap-4 overflow-auto px-5 py-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-4 type-heading1">
          <div>
            <Link to={generateSharedPath(RouteKeys.INSIGHTS)}>
              <span className="text-type-link">{COMPANY_DASHBOARD}</span>
            </Link>
            <span>&nbsp;/&nbsp;</span>
            <span>{props.project?.name}</span>
          </div>
          {props.project.hasAccess && (
            <Link to={generateSharedPath(RouteKeys.PROJECT, { projectId: props.project.id })}>
              <Button endIcon={<LongRightIcon />} label="Go to project" type="secondary" />
            </Link>
          )}
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex">
          <Thumbnail
            dimension={ImageDimension._144}
            padding={0}
            size={120}
            thumbnail={props.project?.thumbnail}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex">{chips}</div>
          {alertChips}
        </div>
        <div className="ml-auto flex flex-col items-end text-right type-body2">
          <ProjectCompanyList projectID={props.project.id} />
          <div className="mt-auto type-body2">
            Last updated {fromNow(props.project.lastUpdated)}
          </div>
        </div>
      </div>
      <InsightsProject project={props.project} />
    </div>
  );
};

export default InsightsProjectDetails;
