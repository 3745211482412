import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import TimelineLegend from '../../Timeline/TimelineLegend/TimelineLegend';
import useMemoWrapper from '../../useMemoWrapper';
import ContingencyLegend from '../Charts/Legends/ContingencyLegend';
import CostLegend from '../Charts/Legends/CostLegend';
import ItemsLegend from '../Charts/Legends/ItemsLegend';
import { InsightsProject as InsightsProjectType } from '../hooks/useInsightsProjectsQuery';
import PendingItemDonutChart from '../PieCharts/PendingItemDonutChart';
import ProjectContingenciesAndAllowances from '../ProjectDetailsCharts/ProjectContingencies/ProjectContingenciesAndAllowances';
import ProjectCost from '../ProjectDetailsCharts/ProjectCost/ProjectCost';
import ProjectItems from '../ProjectDetailsCharts/ProjectItems/ProjectItems';
import ProjectItemsTable from '../ProjectDetailsCharts/ProjectItems/ProjectItemsTable';
import ProjectTimelineChart from '../ProjectDetailsCharts/ProjectTimeline/ProjectTimelineChart';
import ProjectCAndATable from '../Tables/ProjectCAndATable';
import ProjectCostTable from '../Tables/ProjectCostTable';
import SectionTitle from '../Tables/SectionTitle';
import HelpTooltip from '../ToolTips/HelpTooltip';
import { InsightsTabId } from '../types';

import Section from './Section';
import { getProjectRange } from './utils';

type Props = { project: InsightsProjectType };

export default function InsightsProject({ project }: Props) {
  const [startDate, endDate] = useMemoWrapper(getProjectRange, project);
  const { hash } = useLocation();
  const onUpdateHash = (hash: string) => {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  useDebounce(() => onUpdateHash(hash), 100, [hash]);

  const costTrendsTooltip = 'See how costs are trending on your project.';
  const costSummaryTableTooltip =
    'A summary of the current project costs and the potential path to budget.';

  if (!project) return null;

  return (
    <div className="w-full bg-background-primary">
      {/* Timeline */}
      <Section
        id={`${InsightsTabId.Timeline}`}
        legend={<TimelineLegend noPhase />}
        title="Timeline"
      >
        <ProjectTimelineChart
          endStr={endDate.toISOString()}
          project={project}
          startStr={startDate.toISOString()}
        />
      </Section>
      {/* Costs */}
      <Section
        id={`${InsightsTabId.Costs}`}
        legend={<CostLegend />}
        title={
          <div className="flex items-center gap-1">
            <div className="text-type type-heading2">Cost Trends</div>
            <HelpTooltip tooltipContent={costTrendsTooltip} />
          </div>
        }
      >
        <ProjectCost end={endDate} project={project} start={startDate} />
        <Section
          id={`${InsightsTabId.Costs}`}
          title={
            <div className="flex items-center gap-1">
              <div className="text-type type-heading3">Cost Summary</div>
              <HelpTooltip tooltipContent={costSummaryTableTooltip} />
              <div className="ml-56">
                <SectionTitle
                  linkText="View dashboard"
                  to={generateSharedPath(JoinProjectRoutes.PROJECT_DASHBOARD, {
                    projectId: project.id,
                  })}
                />
              </div>
            </div>
          }
        >
          <div className="flex p-2">
            <ProjectCostTable project={project} showTooltips />
          </div>
        </Section>
      </Section>
      {/* Items */}
      <Section
        id={`${InsightsTabId.Items}`}
        title={
          <div className="flex items-center gap-1">
            <div className="text-type type-heading3">Items</div>
            <HelpTooltip tooltipContent="Keep your project decisions and team on track by seeing the status of past and upcoming decisions." />
          </div>
        }
      >
        <Section
          id={InsightsTabId.Items}
          legend={<ItemsLegend />}
          title={<div className="type-heading3">By decision/due date</div>}
        >
          <ProjectItems
            endStr={endDate.toISOString()}
            project={project}
            startStr={startDate.toISOString()}
          />
        </Section>
        <div className="flex">
          <Section
            id={InsightsTabId.Items}
            title={
              <div className="flex items-center gap-1">
                <div className="text-type type-heading3">Pending items</div>
                <HelpTooltip tooltipContent="A summary of pending items by past due, upcoming, and those without due dates." />
              </div>
            }
          >
            <div className="m-7">
              <PendingItemDonutChart project={project} />
            </div>
          </Section>
          <ProjectItemsTable project={project} today={getTodayUtcNoon()} />
        </div>
      </Section>
      {/* Contingencies & Allowances */}
      <Section
        id={`${InsightsTabId.Contingencies}`}
        legend={<ContingencyLegend />}
        title="Contingencies & Allowances"
      >
        <ProjectContingenciesAndAllowances end={endDate} project={project} start={startDate} />
        <ProjectCAndATable project={project} />
      </Section>
    </div>
  );
}
