import { extent } from 'd3-array';

import { DesignPhaseEnum } from '../../api/gqlEnums';
import {
  EventType,
  InsightsMilestone,
  InsightsProjectsBreakdownItem,
  InsightsSortKey,
  SortDirection,
  Status,
  StatusEventsQuery,
} from '../../generated/graphql';
import { setAllDayRange } from '../../utilities/dates';
import { EMPTY_COST, renderPercentString } from '../../utilities/string';
import { isNonNullable } from '../../utilities/types';
import {
  ItemDueState,
  TimelineItemData,
} from '../shared-widgets/TimelineChart/timeline/timeline.types';

import { StackDataItem } from './Charts/DesignPhaseStackedBarChart';
import { HeaderDisplayBy } from './PieCharts/InsightsListHeaderPieBar';

export type StatusEvent = StatusEventsQuery['costTrendlineEvents']['events'][number];

export const getDueDateState = (item: ItemsTimelineItem, todayDate: Date) => {
  const dueDate = new Date(item.dueDate || '0');
  let status = ItemDueState.Upcoming;
  if (dueDate < todayDate && item.status === Status.PENDING) {
    status = ItemDueState.PastDue;
  } else if (
    item.status === Status.ACCEPTED ||
    item.status === Status.REJECTED ||
    item.status === Status.INCORPORATED ||
    item.status === Status.NOTAPPLICABLE
  ) {
    status = ItemDueState.Decided;
  }
  return status;
};

export const getDueDateStateNoDue = (event: StatusEvent | undefined) => {
  let status;
  if (event?.timestamp) {
    const { oldStatus, newStatus } = event.eventContent;
    // Past Bars
    if (
      // pending to anything
      oldStatus === Status.PENDING ||
      // accepted to rejected
      (oldStatus === Status.ACCEPTED && newStatus === Status.REJECTED) ||
      // rejected to accepted
      (oldStatus === Status.REJECTED && newStatus === Status.ACCEPTED)
    ) {
      status = ItemDueState.Decided;
    }

    // accepted to incorporated
    if (oldStatus === Status.ACCEPTED && newStatus === Status.INCORPORATED) {
      // no count
      status = null;
    }
  }
  return status;
};

export const computeItemDecidedDate = (
  list: ItemsTimelineItem[],
  today: string,
  events: EventsTimelineEvent[]
): TimelineItemData[] => {
  // Filter by CHANGE_STATUS event type
  const statusChangeEvents = events
    .filter(({ eventType }) => eventType === EventType.CHANGE_STATUS)
    // Recent events first, descending order
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  // Since we are comparing dueDate < todayDate, it is important to set todayDate to the end of the day.
  setAllDayRange([new Date(today), todayDate]);

  const result = list
    .map((item: ItemsTimelineItem) => {
      const event = statusChangeEvents.find((event) => event.item?.id === item.id);
      // We are interested in the most recent event
      // Due state for items without due date
      const dueState = item.dueDate
        ? getDueDateState(item, todayDate)
        : getDueDateStateNoDue(event);
      if (dueState === null || dueState === undefined) return undefined;
      const useDueDate = dueState === ItemDueState.PastDue || dueState === ItemDueState.Upcoming;
      let dueDate = useDueDate ? item.dueDate || event?.timestamp : event?.timestamp;

      // When the event is null, set the due date to the created date for decided items
      if (!dueDate && dueState === ItemDueState.Decided && !event) {
        dueDate = item.createdAt;
      }

      if (!dueDate) return undefined;
      return {
        id: item.id,
        name: item.name,
        dueDate,
        dueState,
      };
    })
    .filter(isNonNullable);
  // The array of non null TimelineItemData
  return result;
};

type TimeDate = {
  date: Date;
};

/** Moves an array of time series to today's date */
export function fixMocksToday<T extends TimeDate>(data: T[], delta: number) {
  return data.map((d) => ({ ...d, date: new Date(d.date.getTime() + delta) }));
}

export function getRange<T extends TimeDate>(data: T[]): [Date, Date] {
  const set = data.map((d) => d.date);
  const dateRange = extent(set);
  return dateRange as [Date, Date];
}

type StartEnd = {
  startDate: string;
  endDate?: string | null;
};

/** Moves an array of time series to today's date */
export function fixMocksStartEnd<T extends StartEnd>(data: T[], delta: number) {
  return data.map((d) => {
    const obj = {
      ...d,
      startDate: new Date(new Date(d.startDate).getTime() + delta).toISOString(),
    };
    if (!d.endDate) return obj;
    return {
      ...obj,
      endDate: new Date(new Date(d.endDate).getTime() + delta).toISOString(),
    };
  });
}

export function isGmpInsightsMilestone(m: InsightsMilestone) {
  return m.designPhase === DesignPhaseEnum['Guaranteed Maximum Price'];
}

export const calculateDrawPercentage = (remaining: number, total: number): string => {
  if (
    Number.isNaN(remaining) ||
    Number.isNaN(total) ||
    Number(remaining) === 0 ||
    Number(total) === 0
  ) {
    return EMPTY_COST;
  }
  const percentage = Math.round((remaining / total) * 100);
  return total ? renderPercentString({ value: percentage }) : '0%';
};

export const generateDesignPhaseChartInput = (
  designPhaseBreakdown: InsightsProjectsBreakdownItem[],
  selectedDisplayByOption: HeaderDisplayBy
): StackDataItem[] => {
  const reorderedItems = reorderInsightsProjects(designPhaseBreakdown);
  const sortByCountsSelection = selectedDisplayByOption === HeaderDisplayBy.COUNT;

  const dataItems: StackDataItem[] = [];

  reorderedItems.forEach((bi) => {
    const projectsWithAlertsValue = Number(
      sortByCountsSelection ? bi.countWithAlerts : bi.volumeWithAlerts
    );
    const projectsCountValue = Number(
      sortByCountsSelection ? bi.count - bi.countWithAlerts : bi.volume - bi.volumeWithAlerts
    );
    // Only push to dataItems if the value is non-zero
    if (projectsWithAlertsValue !== 0 || projectsCountValue !== 0) {
      dataItems.push({
        category: bi.label,
        values: [
          {
            name: 'projectsWithAlerts',
            value: projectsWithAlertsValue,
          },
          {
            name: 'projectsCount',
            value: projectsCountValue,
          },
        ],
      });
    }
  });

  return dataItems;
};

function reorderInsightsProjects(
  items: InsightsProjectsBreakdownItem[]
): InsightsProjectsBreakdownItem[] {
  return [...items].sort((a, b) => {
    const orderA = designPhaseOrderMap.indexOf(a.label);
    const orderB = designPhaseOrderMap.indexOf(b.label);
    return (orderA === -1 ? Infinity : orderA) - (orderB === -1 ? Infinity : orderB);
  });
}

const designPhaseOrderMap: string[] = [
  DesignPhaseEnum.Programming,
  DesignPhaseEnum['Pre-Design'],
  DesignPhaseEnum['Conceptual Design'],
  DesignPhaseEnum['Schematic Design'],
  DesignPhaseEnum['Design Development'],
  DesignPhaseEnum['Construction Documents'],
  DesignPhaseEnum['Guaranteed Maximum Price'],
  DesignPhaseEnum['As-Built Costs/Final Construction Costs'],
];

const keySortConfig: Record<InsightsSortKey, SortDirection> = {
  // Sort Descending
  [InsightsSortKey.COST]: SortDirection.SORT_DESCENDING,
  [InsightsSortKey.SIZE]: SortDirection.SORT_DESCENDING,
  [InsightsSortKey.LAST_UPDATED]: SortDirection.SORT_DESCENDING,
  // Sorty Ascending
  [InsightsSortKey.NAME]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.DELIVERY_TYPE]: SortDirection.SORT_ASCENDING,
  [InsightsSortKey.STATUS]: SortDirection.SORT_ASCENDING,
  // TODO design phase must be sorted by Sort them by early → late (same order we use in the selector when setting them)
  [InsightsSortKey.DESIGN_PHASE]: SortDirection.SORT_ASCENDING,
};

export const getSortDirection = (sortBy: InsightsSortKey): SortDirection => {
  return keySortConfig[sortBy];
};
