import {
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_TYPE,
} from '../../../constants';
import { InsightsProjectCounts, Org, OrgNode, ProjectType } from '../../../generated/graphql';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { chartColorUnassigned } from '../../Charts/ChartsD3/ChartsPieGraph/utils';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { useCompanyTabID } from '../../CompanyTab/CompanyTabUtils';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';
import useMemoWrapper from '../../useMemoWrapper';

import { HeaderDisplayBy } from './InsightsListHeaderPieBar';

interface Props {
  filterManager: ProjectFilterManager;
  orgs: Org[];
  projectCounts: InsightsProjectCounts;
  selectedDisplayBy: HeaderDisplayBy;
  setSettings: ProjectFilterSetSettings;
}

export default function HeaderDonutCharts(props: Props) {
  // Hooks
  const companyID = useCompanyTabID();
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const allOrgs = organizationsQueryResult.data?.organizations ?? [];
  // Filters
  const { setFilter, setFilters } = props.filterManager;
  // Generate date for donut charts input
  const pieChartDataTypes = useMemoWrapper(
    generatePieChartDataTypes,
    props.projectCounts,
    props.selectedDisplayBy
  );
  const pieChartDataLeads = useMemoWrapper(
    generatePieChartDataLeads,
    props.projectCounts,
    props.selectedDisplayBy
  );
  const { pieChartTopOrgs, selectedOrgsData, pieChartOtherOrgs } = useMemoWrapper(
    generatePieChartDataOrgs,
    props.projectCounts,
    props.orgs,
    props.selectedDisplayBy
  );

  return (
    <div className="flex gap-8">
      {/* Orgs Donut */}
      {selectedOrgsData.map((orgData, i) => {
        return (
          <div key={orgData.orgName}>
            <div>
              <ChartsPieGraph
                centerLabel={{
                  label: orgData.orgName,
                  sublabel: `(${String(pieChartTopOrgs[i].length)})`,
                }}
                chartSize={{
                  diameter: 100,
                  insideDiameter: 70,
                }}
                data={pieChartTopOrgs[i]}
                dataOther={pieChartOtherOrgs[i]}
                displayLegendTooltip
                displaySectionTooltip
                isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
                labelStyle="type-label"
                onSliceClick={(name: string) => {
                  let foundNodes: OrgNode[] = [];

                  allOrgs.some((org) => {
                    const matchedNode = org.nodes.find((node: OrgNode) => node.name === name);
                    if (matchedNode) {
                      // Collect the found node and all of its descendants in a flat list
                      foundNodes = collectNodeAndDescendants(matchedNode.id, org.nodes);
                      return true; // Break the loop once a match is found
                    }
                    return false;
                  });

                  if (foundNodes.length > 0) {
                    setFilters(
                      {
                        type: FILTER_PROJECT_ORGANIZATION,
                        value: '',
                        values: foundNodes.map((n) => n.id),
                      },
                      props.setSettings
                    );
                  }
                }}
                title={orgData.orgName}
                unassignedColor={chartColorUnassigned}
              />
            </div>
          </div>
        );
      })}

      {/* Leads Donut */}
      {pieChartDataLeads.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Leads',
              sublabel: `(${String(pieChartDataLeads.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            data={pieChartDataLeads.topEntries}
            displayLegendTooltip
            displaySectionTooltip
            isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            onSliceClick={(name: string) => {
              const matchedLead = props.filterManager.filterOptions?.projectLeads?.find(
                (lead) => lead.name === name
              );
              if (matchedLead) {
                setFilter({ type: FILTER_PROJECT_LEAD, value: matchedLead.id }, props.setSettings);
              }
            }}
            title="Leads"
            unassignedColor={chartColorUnassigned}
          />
        </div>
      )}

      {/* Types Donut */}
      {pieChartDataTypes.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Type',
              sublabel: `(${String(pieChartDataTypes.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            data={pieChartDataTypes.topEntries}
            displayLegendTooltip
            displaySectionTooltip
            isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            onSliceClick={(name: string) => {
              const types = props.filterManager.filterOptions?.types;
              // Check if types is defined and is of type ProjectType[]
              const matchedType = Array.isArray(types)
                ? (types as ProjectType[]).find((type) => type.name === name)
                : undefined;
              if (matchedType) {
                setFilter({ type: FILTER_PROJECT_TYPE, value: matchedType.id }, props.setSettings);
              }
            }}
            title="Types"
            unassignedColor={chartColorUnassigned}
          />
        </div>
      )}
    </div>
  );
}

//
// Project Types Util
//
const generatePieChartDataTypes = (
  projectCounts: InsightsProjectCounts,
  selectedDisplayBy: HeaderDisplayBy
) => {
  // Map and sort the result
  const pieChartDataTypes = projectCounts.projectTypesBreakdown
    .map((breakdownItem) => ({
      name: breakdownItem.label,
      share:
        selectedDisplayBy === HeaderDisplayBy.COUNT ? breakdownItem.count : breakdownItem.volume,
    }))
    .sort((a, b) => b.share - a.share); // Sort by share value

  let topEntries = pieChartDataTypes;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataTypes.length > 19) {
    topEntries = pieChartDataTypes.slice(0, 19);
    otherEntries = pieChartDataTypes.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

    topEntries.push({ name: 'Other Project Types', share: otherShare });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};

//
// Project Leads Util
//
const generatePieChartDataLeads = (
  projectCounts: InsightsProjectCounts,
  selectedDisplayBy: HeaderDisplayBy
) => {
  // Step 2: Map the results
  let pieChartDataLeads = projectCounts.projectLeadsBreakdown
    .map((breakdownItem) => ({
      name: breakdownItem.label,
      share:
        selectedDisplayBy === HeaderDisplayBy.COUNT ? breakdownItem.count : breakdownItem.volume,
    }))
    .sort((a, b) => b.share - a.share); // Sort by share value

  // Step 3: Sort by share value, but ensure "Unassigned" is always last
  pieChartDataLeads = pieChartDataLeads.sort((a, b) => {
    if (a.name === 'Unassigned') return 1; // "Unassigned" should come last
    if (b.name === 'Unassigned') return -1; // Ensure other entries come before "Unassigned"
    return b.share - a.share; // Sort by share value for all other entries
  });

  let topEntries = pieChartDataLeads;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataLeads.length > 19) {
    topEntries = pieChartDataLeads.slice(0, 19);
    otherEntries = pieChartDataLeads.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

    topEntries.push({ name: 'Other Project Leads', share: otherShare });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};

//
// Orgs Util functions
//
const findTopLevelNode = (node: OrgNode, nodeMap: Map<string, OrgNode>): OrgNode => {
  let currentNode = node;

  while (currentNode && currentNode.parentID) {
    const parentNode = nodeMap.get(currentNode.parentID);
    if (!parentNode) {
      break; // Break the loop if the parent node does not exist in the map
    }
    currentNode = parentNode;
  }

  return currentNode;
};

const generatePieChartDataOrgs = (
  projectCounts: InsightsProjectCounts,
  orgs: Org[],
  selectedDisplayBy: HeaderDisplayBy
) => {
  if (!projectCounts.organizationBreakdowns.length || !orgs.length) {
    return { pieChartTopOrgs: [], selectedOrgsData: [], pieChartOtherOrgs: [] };
  }

  const nodeMap: Map<string, OrgNode> = new Map();
  orgs.forEach((org) => {
    org.nodes.forEach((node: OrgNode) => {
      nodeMap.set(node.id, node);
    });
  });

  // Create a record of top level node for each node
  const nodeToTopLevelMap: Record<string, string> = {};
  const nodeToTopLevelMapIDs: Record<string, OrgNode> = {};
  orgs.forEach((org) => {
    org.nodes.forEach((node: OrgNode) => {
      const topLevelNode = findTopLevelNode(node, nodeMap);
      nodeToTopLevelMap[node.name] = topLevelNode.name;
      nodeToTopLevelMapIDs[node.id] = topLevelNode;
    });
  });

  // Start with creating the organized data for pie charts
  const selectedOrgsData: {
    orgName: string;
    orgID: string;
    selectedNodeDetails: {
      selectedOrgTopLevelNodeName: string;
      count: number;
      runningTotal: number;
    }[];
  }[] = orgs.map((org) => ({
    orgName: org.name,
    orgID: org.id,
    selectedNodeDetails: [],
  }));

  // Loop through all projects and find nodes and relate them to its parent top level node
  projectCounts.organizationBreakdowns.forEach((orgBreakdown) => {
    const matchedOrg = orgs.find((org) => org.id === orgBreakdown.organizationID);

    if (matchedOrg) {
      orgBreakdown.breakdown.forEach((orgBreakdownEntry) => {
        const orgIndex = selectedOrgsData.findIndex(
          (orgData) => orgData.orgName === matchedOrg.name
        );

        if (orgIndex !== -1) {
          let topLevelNodeName = '';
          if (orgBreakdownEntry.label === 'Unassigned') {
            topLevelNodeName = orgBreakdownEntry.label;
          } else {
            // The label passed back from the backend is the org node id.
            topLevelNodeName = nodeToTopLevelMapIDs[orgBreakdownEntry.label].name;
          }

          selectedOrgsData[orgIndex].selectedNodeDetails.push({
            selectedOrgTopLevelNodeName: topLevelNodeName,
            count: orgBreakdownEntry.count,
            runningTotal: orgBreakdownEntry.volume || 0,
          });
        }
      });
    }
  });

  // // Remove orgs with only unassigned entries (ie orgs not used in any project)
  // const orgsDataWithoutUnassigned = selectedOrgsData.filter((orgData) => {
  //   const hasOnlyUnassigned = orgData.selectedNodeDetails.every(
  //     (detail) => detail.selectedOrgTopLevelNodeName === 'Unassigned'
  //   );
  //   return !hasOnlyUnassigned;
  // });

  // Create data for the donut chart
  const pieChartDataOrgs: {
    name: string;
    share: number;
  }[][] = [];

  // Loop through each org and tally up their used top level nodes
  selectedOrgsData.forEach((orgData) => {
    const topLevelNodeCount: Record<string, number> = {};

    orgData.selectedNodeDetails.forEach((detail) => {
      const topLevelNodeName = detail.selectedOrgTopLevelNodeName;
      const incrementValue =
        selectedDisplayBy === HeaderDisplayBy.COUNT ? detail.count : Number(detail.runningTotal);

      topLevelNodeCount[topLevelNodeName] =
        (topLevelNodeCount[topLevelNodeName] || 0) + incrementValue;
    });

    // Create data for pie chart input
    const pieChartDataForOrg = Object.entries(topLevelNodeCount)
      .map(([name, share]) => ({
        name,
        share,
      }))
      .sort((a, b) => {
        // If either entry is "Unassigned", handle it specially
        if (a.name === 'Unassigned') return 1; // Move "Unassigned" to the end
        if (b.name === 'Unassigned') return -1; // Keep "Unassigned" at the end
        return b.share - a.share; // Otherwise, sort by share value
      });

    // Confirm adding only data for orgs that have entries in the current projects list
    if (orgData.selectedNodeDetails.length > 0) pieChartDataOrgs.push(pieChartDataForOrg);
  });

  // Get the first 3 entries of selectedOrgsData where selectedNodeDetails have entries
  const filteredSelectedOrgsData = selectedOrgsData
    .filter((orgData) => orgData.selectedNodeDetails.length > 0)
    .slice(0, 3);

  // Process for entries greater than 19 and create the "Other" entry for the remaining
  const pieChartTopOrgs: {
    name: string;
    share: number;
  }[][] = [];
  const pieChartOtherOrgs: {
    name: string;
    share: number;
  }[][] = [];

  pieChartDataOrgs.forEach((orgData, i) => {
    let topEntries = orgData;
    let otherEntries: { name: string; share: number }[] = [];

    // If more than 19 entries, combine the rest into "Other"
    if (orgData.length > 19) {
      topEntries = orgData.slice(0, 19);
      otherEntries = orgData.slice(19);

      const otherShare = otherEntries.reduce((acc, { share }) => acc + share, 0);

      topEntries.push({ name: `Other ${filteredSelectedOrgsData[i].orgName}`, share: otherShare });
    }

    pieChartTopOrgs.push(topEntries);
    pieChartOtherOrgs.push(otherEntries);
  });

  return { pieChartTopOrgs, selectedOrgsData: filteredSelectedOrgsData, pieChartOtherOrgs };
};

// Function to collect the node and its descendants in a flat list
const collectNodeAndDescendants = (nodeId: string, allNodes: OrgNode[]): OrgNode[] => {
  const result: OrgNode[] = [];
  const collectDescendants = (id: string) => {
    const children = allNodes.filter((node) => node.parentID === id);
    result.push(...children); // Add found children to the result list
    children.forEach((child) => collectDescendants(child.id)); // Recursively collect descendants
  };

  const node = allNodes.find((node) => node.id === nodeId);
  if (node) {
    result.unshift(node); // Add the found node at the start of the result list
    collectDescendants(nodeId);
  }

  return result; // Return the flat list of entries
};
