import { gql } from '@apollo/client';

import {
  programCostBreakdownFields,
  programFields,
  programProjectFields,
} from '../../api/graphqlFragments';

export const programEligibleProjectsQuery = gql`
  query programEligibleProjects {
    programEligibleProjects {
      ...programProjectFields
    }
  }
  ${programProjectFields}
`;

export const programQuery = gql`
  query program($programID: UUID!) {
    program(programID: $programID) {
      ...programFields
    }
  }
  ${programFields}
`;

export const programBreakdownQuery = gql`
  query programBreakdown($programID: UUID!, $projectIDs: [UUID!]!) {
    programBreakdown(programID: $programID, projectIDs: $projectIDs) {
      ...programCostBreakdownFields
    }
  }
  ${programCostBreakdownFields}
`;

export const programCostTrendlineQuery = gql`
  query programCostTrendline($programID: UUID!, $includedProjectIDs: [UUID!]!) {
    programCostTrendline(programID: $programID, includedProjectIDs: $includedProjectIDs) {
      minDate
      maxDate
      minCost
      maxCost
      combined {
        date
        estimate
        budget
        runningTotal
        pendingMin
        pendingMax
      }
      separated {
        projectName
        activeMilestoneName
        projectID
        separatedCostTimeSeries {
          date
          gap
          runningTotal
          monthOverMonthPercentChange
        }
      }
    }
  }
`;
