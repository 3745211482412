import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import { JoinCompanyRoutes } from '../../api/gqlEnums';
import { COMPANY_DASHBOARD } from '../../constants';
import { InsightsSortKey, SearchResultType } from '../../generated/graphql';
import useNextPageLoadingState from '../../hooks/useNextPageLoadingState';
import { useProjectDeliveryTypes } from '../../hooks/useProjectDeliveryTypesQuery';
import { generateSharedPath } from '../../utilities/routes/links';
import { usePersistentStates } from '../../utilities/urlState';
import { useGetAlerts } from '../CompanyTab/CompanyHooks';
import useOrganizationsQuery from '../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import Settings from '../Icons/Settings';
import { useDesignPhaseTypes } from '../Milestone/hooks/useDesignPhaseTypesQuery';
import { ProjectIcon } from '../Nav/icons';
import useSearchProjectsFilterOptionsQuery from '../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';
import { Button, Tooltip } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import DesignPhaseStackedBarChart from './Charts/DesignPhaseStackedBarChart';
import CompanySelector from './CompanySelector';
import InsightsFilterMenu from './FilterMenu/InsightsFilterMenu';
import { InsightsProject, useInsightsProjectsQuery } from './hooks/useInsightsProjectsQuery';
import { useInsightsV2ProjectCountsQuery } from './hooks/useInsightsV2ProjectCountsQuery';
import InsightsProjectDetails from './InsightsProject/InsightsProjectDetails';
import InsightsTabs from './InsightsTabs';
import HeaderDonutCharts from './PieCharts/HeaderDonutCharts';
import InsightsListCountsVolBar, { HeaderDisplayBy } from './PieCharts/InsightsListHeaderPieBar';
import ProjectsListTables from './ProjectsListTables';
import ProjectsSummaryStackedTooltip from './ToolTips/ProjectsSummaryStackedTooltip';
import { InsightsTabId } from './types';
import { generateDesignPhaseChartInput, getSortDirection } from './utils';

export default function InsightsProjectsList() {
  const { projectId } = useParams();
  const [selectedTabID, setSelectedTabID] = useState(InsightsTabId.Costs);

  // Company data
  const companyID = useCompanyTabID();
  // Sorting
  const [sortState, setSortState] = useState({
    sortKey: InsightsSortKey.LAST_UPDATED,
    sortDirection: getSortDirection(InsightsSortKey.LAST_UPDATED),
  });
  const setSort = (sortBy: InsightsSortKey) => {
    const sortDirection = getSortDirection(sortBy);
    setSortState({ sortKey: sortBy, sortDirection });
  };
  // Filtering
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    {
      deliveryMethods: [],
      estimateCostRange: {
        max: null,
        min: null,
      },
      gsfRange: {
        max: null,
        min: null,
      },
      statuses: [],
      types: [],
      projectTypes: [],
      companies: [],
      locations: [],
      orgNodeIDs: [],
      milestoneDesignPhases: [],
      projectLeadIDs: [],
      designPhases: [],
    },
    'Insights Filters - '
  );
  // Filters
  const filterManager = useFilterProjects(settings);
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);

  // Organizations
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  // Design Phases
  const designPhaseTypes = useDesignPhaseTypes();
  // Project Delivery Types
  const projectDeliveryTypes = useProjectDeliveryTypes();

  const [alertsOnly, setAlertsOnly] = useState(false);
  const alertsData = useGetAlerts({
    variables: { companyID: companyID || '' },
  });
  const activeAlerts = alertsData.data?.getAlerts.filter((alert) => alert.isActive);

  if (filterOptionsData && designPhaseTypes && projectDeliveryTypes) {
    const intialFilterOptions: SearchProjectsFilterOptions = cloneDeep(
      filterOptionsData.searchProjectsFilterOptions
    );
    intialFilterOptions.gsfRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.estimateCostRange = {
      max: null,
      min: null,
    };
    intialFilterOptions.designPhases = designPhaseTypes.map((d) => d.name) || [];
    intialFilterOptions.deliveryMethods = projectDeliveryTypes.map((d) => d.name) || [];
    filterManager.filterOptions = intialFilterOptions;
    if (filterManager.filterOptions)
      filterManager.filterOptions.types =
        filterOptionsData.searchProjectsFilterOptions.projectTypes || [];
  }
  const insightsInput = {
    companyID: companyID || '',
    filter: {
      deliveryTypes: filterManager.filterState?.deliveryMethods || [],
      gsfRange:
        typeof filterManager.filterState.gsfRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.gsfRange,
      statuses: filterManager.filterState?.statuses || [],
      types:
        filterOptionsData?.searchProjectsFilterOptions.projectTypes
          .filter((t) => filterManager.filterState?.types?.includes(t.id))
          .map((t) => t.name) || [],
      companies: filterManager.filterState?.companies || [],
      estimateCostRange:
        typeof filterManager.filterState.estimateCostRange === 'string'
          ? {
              max: null,
              min: null,
            }
          : filterManager.filterState.estimateCostRange,
      locations: filterManager.filterState?.locations || [],
      orgNodeIDs: filterManager.filterState?.orgNodeIDs || [],
      milestoneDesignPhases: filterManager.filterState?.designPhases || [],
      projectLeadIDs: filterManager.filterState?.projectLeadIDs || [],
      alertsOnly,
    },
    sort: sortState,
  };
  const projectCounts = useInsightsV2ProjectCountsQuery(insightsInput) || {
    filteredProjects: 0,
    totalProjects: 0,
    totalAlerts: 0,
    projectsWithAlerts: 0,
    organizationBreakdowns: [],
    projectLeadsBreakdown: [],
    projectTypesBreakdown: [],
    designPhasesBreakdown: [],
  };
  const { data: insightsProjects, fetchMore } = useInsightsProjectsQuery({
    input: insightsInput,
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  // Header pie charts display selector
  const [selectedDisplayByOption, setSelectedDisplayByOption] = useState<HeaderDisplayBy>(
    HeaderDisplayBy.COUNT
  );
  const handleOptionChange = (option: HeaderDisplayBy) => {
    setSelectedDisplayByOption(option);
  };

  // Generate design phase chart input data
  const designPhaseChartData = useMemoWrapper(
    generateDesignPhaseChartInput,
    projectCounts.designPhasesBreakdown,
    selectedDisplayByOption
  );

  if (projectId) {
    const project = insightsProjects?.find(({ id }) => id === projectId);
    return project ? (
      /** TODO: Make sure that we use InsightsProjectRoute with Outlet */
      <InsightsProjectDetails project={project} />
    ) : null;
  }

  const projectAlertsString = `project${
    projectCounts.projectsWithAlerts !== 1 ? 's' : ''
  } with alerts`;
  return (
    <div className="flex h-full max-w-full grow flex-col gap-2">
      <div className="flex flex-col gap-2 px-5 py-4">
        <div className="flex items-center justify-between pb-6">
          <div className="flex items-center gap-2">
            <div className="flex type-heading1">{COMPANY_DASHBOARD}</div>
            <CompanySelector />
          </div>
          <div className="flex items-center gap-2">
            <InsightsFilterMenu filterManager={filterManager} setSettings={setSettings} />
            <Link to={generateSharedPath(JoinCompanyRoutes.COMPANY_INSIGHTS_ALERTS, {})}>
              <Button
                label={`Configure Alerts (${activeAlerts?.length || 0})`}
                startIcon={<Settings />}
                type="secondary"
              />
            </Link>
          </div>
        </div>

        <div className="flex items-center justify-end gap-1">
          <div className="type-body1">Display by</div>
          <InsightsListCountsVolBar
            onOptionChange={handleOptionChange}
            selectedOption={selectedDisplayByOption}
          />
        </div>

        <div className="flex h-36 flex-row justify-between">
          <div className="flex flex-col">
            <div className="type-label">Alerts</div>
            <div className="flex items-center gap-1">
              <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
              <div className="text-type-error type-heading3">
                {projectCounts.totalAlerts} alerts
              </div>
            </div>
            <div className="flex items-center gap-1">
              <ProjectIcon />
              <div className="type-heading3">
                {projectCounts.projectsWithAlerts} {projectAlertsString}
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center gap-8">
            {/* Header Design Phases Stacked Chart */}
            <div className="flex flex-col">
              <Tooltip
                content={
                  <div>
                    <ProjectsSummaryStackedTooltip
                      selectedDisplayBy={selectedDisplayByOption}
                      stackData={designPhaseChartData}
                    />
                  </div>
                }
              >
                <div>
                  <DesignPhaseStackedBarChart data={designPhaseChartData} />
                </div>
              </Tooltip>
            </div>
            {/* //Header Donut Charts */}
            <HeaderDonutCharts
              filterManager={filterManager}
              orgs={orgs || []}
              projectCounts={projectCounts || []}
              selectedDisplayBy={selectedDisplayByOption}
              setSettings={setSettings}
            />
          </div>
        </div>

        <div className="flex flex-col justify-stretch">
          <InsightsTabs
            alertsOnly={alertsOnly}
            onSortByChange={setSort}
            onTabChange={setSelectedTabID}
            selectedSortBy={sortState.sortKey}
            selectedTabID={selectedTabID}
            setAlertsOnly={setAlertsOnly}
          />
        </div>
      </div>

      <ProjectsListWithPageLoading
        insightsProjects={insightsProjects || []}
        onFetchMore={fetchMore}
        projectCounts={{
          current: projectCounts.filteredProjects,
          total: projectCounts.totalProjects,
        }}
        tabId={selectedTabID}
      />
    </div>
  );
}

export function ProjectsListWithPageLoading(props: {
  projectCounts: { current: number; total: number };
  insightsProjects: InsightsProject[];
  tabId: InsightsTabId;
  onFetchMore: () => void;
}) {
  const insightsProjects = props.insightsProjects;
  const [pageLoading, setLoadingFor] = useNextPageLoadingState(insightsProjects);
  const onSetLoadingFor = useCallback(
    () => setLoadingFor(insightsProjects),
    [insightsProjects, setLoadingFor]
  );

  const loadingFooter = (
    <div key="loading" className="mx-auto mt-2 h-20 w-full">
      <div className="flex h-full items-center justify-center">
        <CircularProgress />
      </div>
    </div>
  );

  return (
    <>
      <div className="mx-3 flex-1 overflow-y-auto">
        <ProjectsListTables
          {...props}
          onFetchMore={() => {
            props.onFetchMore();
            onSetLoadingFor();
          }}
        />
      </div>
      {pageLoading && loadingFooter}
    </>
  );
}
