import { AssetsQuery, Visibility } from '../../../../generated/graphql';
import AssetsCardAddFile from '../../../assets/AssetsCards/AssetsCard/AssetsCardAddFile';
import AssetsCardData from '../../../assets/AssetsCards/AssetsCard/AssetsCardData';
import { Button, Dialog, DialogContent } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';

type ItemAttachmentsDialogItemCopyProps = {
  itemAssets: AssetsQuery['assets'];
  onBack: () => void;
  onClose: () => void;
  onDownload: (asset: Asset) => void;
  onNext: () => void;
  onRemove: (assetID: UUID) => void;
  progressPercent?: number;
  onAddFiles: () => void;
  state: ItemCopyState;
};

const ItemAttachmentsDialogItemCopy = (props: ItemAttachmentsDialogItemCopyProps) => {
  const createButtonCopy =
    props.state.visibility === Visibility.PRIVATE_DRAFT ? 'Create as Draft' : 'Create and Publish';
  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onBack} type="secondary" />}
      footerRight={
        <Button
          disabled={!props.state.name}
          label={createButtonCopy}
          onClick={props.onNext}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Edit Item Attachments"
    >
      <DialogContent>
        <div className="flex flex-col gap-6">
          <div className="flex flex-wrap gap-4">
            {props.itemAssets?.map((asset) => (
              <div key={asset?.id} className="flex flex-col gap-1">
                <AssetsCardData asset={asset as Asset} canDelete showMenu={false} />
                <button
                  className="type-small-link"
                  onClick={() => props.onDownload(asset as Asset)}
                >
                  Download
                </button>
                <button
                  className="text-type-delete outline-none type-label hover:underline"
                  onClick={() => props.onRemove(asset?.id || '')}
                >
                  Remove
                </button>
              </div>
            ))}
            <AssetsCardAddFile onClick={props.onAddFiles} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ItemAttachmentsDialogItemCopy;
