import { useEffect, useMemo } from 'react';

import { Divider } from '@material-ui/core';

import { PageViews } from '../../../../analytics/analyticsEventProperties';
import { SearchResultType } from '../../../../generated/graphql';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ProjectsFilters from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import SearchHeader from '../SearchHeader';

import { activeFiltersCountProjects } from './activeFilterCountProjects';
import { useSearchProjectsQuery } from './hooks/useSearchProjectsQuery';
import useSearchProjectsQueryParams from './hooks/useSearchProjectsQueryParams';
import { useUpdateSearchProjectsFromURL } from './hooks/useUpdateSearchProjectsFromURL';
import LeadsBanner from './LeadsBanner';
import SearchProjectsList from './SearchProjects';
import { useFilterOptions } from './utils';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const SearchProjectsData = (props: Props) => {
  useEffect(() => {
    document.title = 'Projects';
  }, []);

  const params = useSearchProjectsQueryParams();
  useUpdateSearchProjectsFromURL(
    params.onChangeSearch,
    params.onResetFilters,
    params.toggleParams?.onChange
  );

  const { data, fetchMore, loading, previousData } = useSearchProjectsQuery(params.variables);
  const { searchResultType, filters } = params.variables;
  const filterOptions = useFilterOptions(searchResultType !== SearchResultType.MY, filters);
  const projects = data?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  const counts = {
    current: data?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0,
    total:
      searchResultType !== SearchResultType.MY ? params.toggleCounts.all : params.toggleCounts.my,
  };

  const activeFiltersCount = useMemo(() => {
    return activeFiltersCountProjects(filters);
  }, [filters]);

  return (
    <div className="flex h-full grow flex-col">
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        searchPlaceholder="Search projects"
        toggleParams={params.toggleParams}
      />
      <Divider />
      <LeadsBanner
        description="Update the Leads for projects in Company Settings > Project Stats."
        page={PageViews.PROJECT_LIST}
      />
      <div className="flex flex-grow overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded
              onChangeFilters={params.onChangeFilters}
            />
          </AdvancedFilters>
        )}
        <div className="grow overflow-x-auto">
          <SearchProjectsList
            counts={counts}
            loading={loading}
            onFetchMore={fetchMore}
            projects={projects}
            searchText={params.variables.search}
            sortManager={params.sortManager}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchProjectsData;
