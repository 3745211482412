import { ContingenciesTimeseries } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import { InsightsTabId } from '../types';

export const IS_ZOOM = false;

export const getProjectsRange = (date: Date, monthCount = 6): [Date, Date] => {
  // Date 6 months before
  const monthsBefore = new Date(date);
  monthsBefore.setMonth(monthsBefore.getMonth() - monthCount);
  // Date 6 months after
  const monthsAfter = new Date(date);
  monthsAfter.setMonth(monthsAfter.getMonth() + monthCount);
  return [monthsBefore, monthsAfter];
};

export const getCandAPieCharData = (
  data: ContingenciesTimeseries | null,
  project: InsightsProject
) => {
  const pieChartData = [];
  let caTotalPct = 0.0;
  if (data?.allowanceTotal && project.estimate) {
    pieChartData.push({
      name: 'Allowance',
      share: data.allowanceTotal / project.estimate,
    });
    caTotalPct += data.allowanceTotal / project.estimate;
  }

  if (data?.contingencyTotal && project.estimate) {
    pieChartData.push({
      name: 'Contingency',
      share: data.contingencyTotal / project.estimate,
    });
    caTotalPct += data.contingencyTotal / project.estimate;
  }
  pieChartData.push({
    name: 'Remaining',
    share: 1.0 - caTotalPct,
  });
  return { pieChartData, caTotalPct };
};

export const calculatePercentage = (count: number, total: number): number => {
  if (Number(count) === 0 || Number(total) === 0) {
    return 0;
  }
  return Math.floor((100 * count) / total);
};

export const generateProjectPath = (projectId: string, tabId?: InsightsTabId) => {
  const path = {
    pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, { projectId }),
  };

  if (tabId) {
    return { ...path, hash: `#${tabId}` };
  }

  return path;
};
