import {
  GetSharedResourceQuery,
  GetSharedResourceQueryVariables,
  GetSharedResourcesQuery,
  GetSharedResourcesQueryVariables,
  ResourceType,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { getSharedResourceQuery, getSharedResourcesQuery } from './queries';

export const useGetSharedResources = (
  resourceIDs: UUID[],
  resourceType: ResourceType,
  batch = false,
  skip = false
) =>
  useQuery<GetSharedResourcesQuery, GetSharedResourcesQueryVariables>(getSharedResourcesQuery, {
    variables: { input: { resourceIDs, resourceType } },
    skip: skip || resourceIDs.length === 0,
    context: { batch },
  });

export const useGetSharedResource = (
  resourceID: UUID | null | undefined,
  resourceType: ResourceType,
  skip?: boolean
) =>
  useQuery<GetSharedResourceQuery, GetSharedResourceQueryVariables>(getSharedResourceQuery, {
    variables: { resourceID, resourceType },
    skip: skip || !resourceID,
    context: { batch: true },
  });
