import { FC, Fragment } from 'react';

import { calculatePercentage } from '../ProjectsTables/utils';

type Props = {
  totalPendingItems: number;
  pendingItemsDueInFuture: number;
  pendingItemsMissingDueDates: number;
  pendingItemsPastDue: number;
};

const PendingPieToolTip: FC<Props> = (props) => {
  const itemsFillerData = [
    {
      label: 'Past Due',
      percentage: calculatePercentage(props.pendingItemsPastDue, props.totalPendingItems),
      count: props.pendingItemsPastDue,
      color: 'var(--colors-entities-item-pastdue)',
    },
    {
      label: 'Due in the future',
      percentage: calculatePercentage(props.pendingItemsDueInFuture, props.totalPendingItems),
      count: props.pendingItemsDueInFuture,
      color: 'var(--colors-item-status-pending)',
    },
    {
      label: 'Missing Due Dates',
      percentage: calculatePercentage(props.pendingItemsMissingDueDates, props.totalPendingItems),
      count: props.pendingItemsMissingDueDates,
      color: 'var(--colors-item-status-pending-tint)',
    },
  ];

  return (
    <div className="">
      <div className="mb-2 type-heading3">Pending Items</div>
      <div className="grid grid-cols-[160px_40px_40px] gap-2 text-left">
        {itemsFillerData.map((item) => (
          <Fragment key={item.label}>
            <div className="flex items-center type-body2">
              <div className="mr-2 h-3 w-3" style={{ backgroundColor: item.color }} />
              <div className="w-full">{item.label}</div>
            </div>
            <div className="text-right type-body2">{item.percentage}%</div>
            <div className="text-right type-body2">{item.count}</div>
          </Fragment>
        ))}
        {/* Total row */}
        <div className="col-span-2 type-heading3">Total</div>
        <div className="text-right type-heading3">{props.totalPendingItems}</div>
      </div>
    </div>
  );
};

export default PendingPieToolTip;
