import { getCostReportByType, renderCostString } from '../components/CostReport/CostReportUtils';
import { CostReportColumnType } from '../generated/graphql';

import stableSort from './stableSort';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const sortedAndFormatted = (datedItemsLocked: any, currentId = null as any) => {
  if (!datedItemsLocked) return []; // this shouldn't happen
  const datedItems = [...datedItemsLocked].map((o) => ({ ...o }));
  let next;
  const today = new Date();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const sortedDatedItems = stableSort(datedItems, (a: any, b: any) => {
    if (b.date && !a.date) return 1;
    if (a.date && !b.date) return -1;
    if (!a.date && !b.date) return 0;
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    if (aDate === bDate) return 0;
    return aDate < bDate ? 1 : -1;
  });
  for (let i = 0; i < sortedDatedItems.length; i += 1) {
    const d = sortedDatedItems[i];
    // format date for datepicker
    if (d.date) d.date = new Date(d.date).toLocaleString();
    if (d.buckets) d.buckets = sortedAndFormatted(d.buckets);
    if (!currentId) {
      // If there's no active milestone
      d.status = 'future';
    } else if (d.id === currentId) {
      next = d;
      d.status = 'current';
    } else if (!next && (new Date(d.date) < today || currentId)) {
      d.status = 'complete';
    } else {
      // if it's not past or current it's future
      d.status = 'future';
    }
  }
  return sortedDatedItems;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const computeMilestonesSorted = (milestones: any, active: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  sortedAndFormatted(milestones, active).map((milestone: any) => ({ ...milestone }));

export const computeColumnInputs = (
  sidebarColumnTypes: CostReportColumnType[],
  quantity?: Quantity | null
) => {
  const newColumns: CostReportColumnInput[] = [];
  sidebarColumnTypes.forEach((type: CostReportColumnType) => {
    if (quantity) newColumns.push({ type, unitID: quantity.unit.id });
    newColumns.push({ type });
  });
  return newColumns;
};

export const computeSelectCostReport = (
  milestoneCostReports?: MilestoneCostReports,
  quantity?: Quantity
) => {
  let selectCostReport: MilestoneCostReport = (milestoneCostReports || [])[0];
  if (selectCostReport) {
    const costReportColumns = selectCostReport.costReportColumns.filter((column) =>
      quantity
        ? !!column.quantity && column.quantity.unit.id === quantity.unit.id
        : !column.quantity
    );
    selectCostReport = { ...selectCostReport, costReportColumns };
  }
  return selectCostReport;
};

export const computeCostString = (
  milestoneCostReports: MilestoneCostReports,
  quantity: Quantity | undefined,
  type: CostReportColumnType
) => {
  const costReport = computeSelectCostReport(milestoneCostReports, quantity);
  const estimate = getCostReportByType(costReport, type);

  return renderCostString({
    cost: estimate?.range,
    isExact: true,
    isWide: true,
    showCents: false,
  });
};
