import { ProjectCostBreakdown } from '../../generated/graphql';
import ProjectCostBreakdownReportChart from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportChart';

type Props = {
  breakdown: Omit<ProjectCostBreakdown, 'type'>[];
  colorMap: Map<string, string>;
  name: string;
  total: number;
};

export default function ProgramDashboardPieChart({ breakdown, colorMap, name, total }: Props) {
  return (
    <div>
      <div className="type-heading2">{name}</div>
      <div className="flex w-full justify-center">
        <div className="w-[1000px]">
          <ProjectCostBreakdownReportChart
            costBreakdownTypeToColorMap={colorMap}
            label="Running Total"
            projectCostBreakdownReport={{ breakdown, total }}
          />
        </div>
      </div>
    </div>
  );
}
