import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';

import ProgramDashboardData from './ProgramDashboardData';

export default function ProgramDashboardRoute() {
  const { programID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!programID) {
      // redirect to the projects page if no programID is found
      navigate(generateSharedPath(RouteKeys.SEARCH_PROJECTS));
    }
  }, [navigate, programID]);

  if (!programID) {
    return <></>;
  }

  return <ProgramDashboardData programID={programID} />;
}
