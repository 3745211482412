import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  INSIGHTS_DEFAULT_MAX_ITEMS_BAR,
  INSIGHTS_DEFAULT_TIME,
  insightsMaxItemsBar,
  insightsTimeDomain,
} from '../../../api/apollo/reactiveVars';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { formatNumber } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { chartColorUnassigned } from '../../Charts/ChartsD3/ChartsPieGraph/utils';
import ContingencyBar from '../../ContingencyReport/ContingencyReport/BarGraph/ContingencyReportBarGraph';
import { Checkbox, SortManager, Table, Tooltip } from '../../scales';
import ContingencyLegend from '../Charts/Legends/ContingencyLegend';
import XAxis from '../Charts/XAxis';
import ZoomTimeline from '../Charts/ZoomTimeline';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import ProjectContingenciesAndAllowances from '../ProjectBabyCharts/ProjectContingenciesAndAllowances';
import ContingenciesAllowanceBarToolTip from '../ToolTips/ContingenciesAllowanceBarToolTip';
import ContingenciesPieToolTip from '../ToolTips/ContingenciesPieToolTip';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { generateProjectPath, getCandAPieCharData, getProjectsRange } from './utils';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
};

const ProjectsContingenciesAndAllowances: FC<Props> = (props) => {
  useEffectOnce(() => () => {
    insightsTimeDomain(INSIGHTS_DEFAULT_TIME);
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const setStartEnd = ([start, end]: string[]) => {
    setStart(new Date(start));
    setEnd(new Date(end));
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: (
      <ZoomTimeline
        onZoom={onZoom}
        projects={props.projects}
        zoom={[start.toISOString(), end.toISOString()]}
      />
    ),
  });

  const [hasContingency, setHasContingency] = useState(true);
  const [hasAllowance, setHasAllowances] = useState(true);
  const contingencyCheckbox = (
    <Checkbox
      isSelected={hasContingency}
      onChange={() => {
        // never have a situation where both checkboxes are unchecked
        if (hasContingency && !hasAllowance) {
          setHasAllowances(!hasAllowance);
        }
        setHasContingency(!hasContingency);
      }}
    >
      <></>
    </Checkbox>
  );
  const allowanceCheckbox = (
    <Checkbox
      isSelected={hasAllowance}
      onChange={() => {
        // never have a situation where both checkboxes are unchecked
        if (hasAllowance && !hasContingency) {
          setHasContingency(!hasContingency);
        }
        setHasAllowances(!hasAllowance);
      }}
    >
      <></>
    </Checkbox>
  );

  const chartsHeader = (
    <div className="w-full">
      <div className="flow-root h-8 w-full items-center">
        <div className="float-left type-table-header">Over timeline</div>
        <div className="float-right -mt-2">
          <ContingencyLegend
            allowanceCheckbox={allowanceCheckbox}
            contingencyCheckbox={contingencyCheckbox}
          />
        </div>
      </div>
      <div className="-mb-[9px]">
        <XAxis placement="top" totalRange={[start.toISOString(), end.toISOString()]} />
      </div>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const mostRecentData =
            project.contingenciesTimeseries.length > 0
              ? project.contingenciesTimeseries[project.contingenciesTimeseries.length - 1]
              : null;

          const { caTotalPct, pieChartData } = getCandAPieCharData(mostRecentData, project);

          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                >
                  <div>
                    <ProjectTableEntry
                      hasAlert={project.activeAlerts.length > 0}
                      project={project}
                      to={generateProjectPath(project.id)}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesPieToolTip
                        currentContingencyData={mostRecentData}
                        estimateTotal={project.estimate || 1}
                      />
                    </div>
                  }
                >
                  <div>
                    <ChartsPieGraph
                      centerLabel={{
                        label: '',
                        sublabel: `${String(Math.floor(caTotalPct))}%`,
                      }}
                      chartSize={{
                        diameter: 60,
                        insideDiameter: 35,
                      }}
                      colors={[
                        'var(--colors-theme-bauhaus-marcelbreuer)',
                        'var(--colors-theme-bauhaus-laszlo)',
                        'var(--colors-item-status-not-applicable-tint)',
                      ]}
                      data={pieChartData}
                      unassignedColor={chartColorUnassigned}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesAllowanceBarToolTip type="Contingency" />
                    </div>
                  }
                  isDisabled
                >
                  <div>
                    <ContingencyBar
                      drawn={0}
                      isInsights
                      overdrawLimit={0.15}
                      pending={mostRecentData?.contingencyPending || 0}
                      starting={mostRecentData?.contingencyTotal || 0}
                      used={mostRecentData?.contingencyDraw || 0}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ContingenciesAllowanceBarToolTip type="Allowance" />
                    </div>
                  }
                  isDisabled
                >
                  <div>
                    <ContingencyBar
                      drawn={0}
                      isInsights
                      isOrangeTheme
                      overdrawLimit={0.15}
                      pending={mostRecentData?.allowancePending || 0}
                      starting={mostRecentData?.allowanceTotal || 0}
                      used={mostRecentData?.allowanceDraw || 0}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              component: (
                <ProjectContingenciesAndAllowances
                  end={end}
                  hasAllowance={hasAllowance}
                  hasContingency={hasContingency}
                  onClick={() =>
                    navigate(generateProjectPath(project.id, InsightsTabId.Contingencies))
                  }
                  project={project}
                  start={start}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
          },
          {
            copy: '% of Est',
            key: 'est',
          },
          {
            copy: 'Contingencies',
            key: 'cont',
          },
          { copy: 'Allowances', key: 'all' },
          { component: chartsHeader, key: 'ca' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsContingenciesAndAllowances;
