import { PieChartData } from './ChartsPieGraphUtils';

export const getColor = (colors: string[], data: PieChartData, index: number) => {
  if (data.name.includes('Other ')) {
    return chartColorOther;
  }
  if (data.name === 'Unassigned') {
    return chartColorUnassigned;
  }
  return colors[index];
};

export const pieChartDefaultColors = [
  '#000C72',
  '#2B56ED',
  '#4D9CD5',
  '#9ACDF0',
  '#32B8B2',
  '#65E7DB',
  '#59AA83',
  '#72B700',
  '#C5D849',
  '#A19200',
  '#DCB34A',
  '#F9D167',
  '#F49144',
  '#C6620A',
  '#FD9C90',
  '#E8548D',
  '#B10080',
  '#ECC9FF',
  '#DC99FF',
  '#7B45F0',
];

export const chartColorOther = 'var(--colors-button-primary-hover)';
export const chartColorUnassigned = 'var(--colors-item-status-not-chosen-tint)';
