import { Tooltip } from '../../scales';

type Props = {
  className: string;
  costExact?: string;
  costPercentage?: string;
  costRounded?: string;
  description: string;
  icon?: JSX.Element;
  isBold?: boolean;
  label: string;
  showTooltips?: boolean;
  'data-cy': string;
};

export default function ProjectCostRow(props: Props) {
  const content = (
    <div className="flex">
      <div className="flex w-8 items-center justify-center text-right">{props.icon}</div>
      <div className={`line-clamp-1 ${props.showTooltips ? 'w-[300px]' : 'w-[150px]'}`}>
        {props.label}
      </div>
      <div className="w-10 text-right">{props.costPercentage}</div>
      <div className="w-32 text-right" data-cy={props['data-cy']}>
        {props.costRounded}
      </div>
    </div>
  );

  return (
    <Tooltip
      content={
        <div className="flex flex-col gap-0.5">
          <div className="type-label">{props.description}</div>
          <div className="type-table-number">{props.costExact}</div>
        </div>
      }
      isDisabled={!props.costExact || !props.showTooltips}
      offset={20}
      placement="right"
    >
      <div className={`${props.className} flex items-center justify-between gap-1 type-table-text`}>
        {props.isBold ? <b>{content}</b> : content}
      </div>
    </Tooltip>
  );
}
