import * as d3 from 'd3';
import { useState } from 'react';

import { ProgramSepratedCostTrendlines } from '../../../../generated/graphql';
import { formatCost } from '../../../../utilities/currency';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import { ButtonBar } from '../../../scales';
import CostFillPattern from '../ChartsCostTrendline/CostFillPattern';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import { CostTimeSeries } from '../InsightsCost/types';
import TimelineArea from '../TimelineArea';
import TimelinePath from '../TimelinePath';
import TimelinePointTooltip from '../TimelinePointTooltip';

import CombinedHoverContent from './CombinedHoverContent';
import ProgramCostTrendlineLegend from './ProgramCostTrendlineLegend';
import ProgramXAxis from './ProgramXAxis';
import ProgramYAxis from './ProgramYAxis';

type Props = {
  combined?: CostTimeSeries[];
  separated?: ProgramSepratedCostTrendlines[];
  isPrint?: boolean;
  dataRange: [number, number];
  timeRange: [Date, Date];
  height: number;
};

export default function ProgramCostTrendlineChart(props: Props) {
  const [chartType, setChartType] = useState<'combined' | 'separated'>('combined');
  const [hoverInfo, setHoverInfo] = useState<{ date: Date; value: number } | null>(null);

  const { ref, dimensions } = useChartDimensions({
    height: props.height,
    marginTop: 0,
    marginRight: 100,
    marginBottom: 50,
    marginLeft: 100,
  });

  if (!props.combined || !props.separated) return null;
  // x domain
  const xMin = props.timeRange[0];
  const xMax = props.timeRange[1];
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [
    dimensions.marginLeft,
    dimensions.width - dimensions.marginRight - dimensions.marginLeft,
  ];

  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const yDataMin = props.dataRange[0];
  const yDataMax = props.dataRange[1];
  const yDelta = yDataMax - yDataMin;

  // Add vertical padding
  const yMin = Number(yDataMin) - (6 * Number(yDelta)) / 100;
  const yMax = Number(yDataMax) + (18 * Number(yDelta)) / 100;
  const yDomain: [number, number] = [yMin, yMax];
  const yRange: [number, number] = [
    dimensions.height - dimensions.marginBottom,
    dimensions.marginTop,
  ];

  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="type-heading2">Combined Cost Trendline</div>
        <div>
          <ButtonBar
            onChange={(value) => {
              setChartType(value as 'combined' | 'separated');
            }}
            options={[
              {
                label: 'Combined',
                value: 'combined',
              },
              {
                label: 'Separated',
                value: 'separated',
              },
            ]}
            value={chartType}
          />
        </div>
      </div>
      <ProgramCostTrendlineLegend />
      <SVGWithDimensions ref={ref} dimensions={dimensions}>
        <ProgramXAxis
          domain={d3.timeDays(xDomain[0], xDomain[1])}
          range={xRange}
          tickTotal={5}
          x={x}
          y={y}
          yPosition={yMin}
        />
        <ProgramYAxis
          showHorizontalLines
          tickFormat={formatCost}
          tickTotal={5}
          x={x}
          xPosition={xMin}
          y={y}
        />

        {chartType === 'combined' && (
          <>
            <TimelineArea<{ pendingMin: number; pendingMax: number }, { date: Date }>
              data={props.combined}
              fieldDate="date"
              fieldMax="pendingMax"
              fieldMin="pendingMin"
              fill="url(#stripes)"
              stroke="var(--colors-chart-pending-cost-area)"
              x={x}
              y={y}
            />
            <TimelinePath<{ estimate: number }>
              data={props.combined}
              field="estimate"
              stroke="stroke-entities-estimate"
              strokeDasharray="1, 2"
              strokeWidth={1.5}
              x={x}
              y={y}
            />
            <TimelinePath<{ budget: number }>
              data={props.combined}
              field="budget"
              stroke="stroke-entities-budget"
              strokeWidth={1.5}
              x={x}
              y={y}
            />
            <TimelinePath<{ runningTotal: number }>
              data={props.combined}
              field="runningTotal"
              stroke="stroke-entities-estimate"
              strokeWidth={1.5}
              x={x}
              y={y}
            />

            {/* hover points */}
            {!props.isPrint &&
              props.combined.map((point, _) => (
                <>
                  <TimelinePointTooltip
                    key={`point-${point.date}`}
                    content={<CombinedHoverContent costData={point} />}
                    data={{
                      date: point.date,
                      value: point.estimate,
                    }}
                    fill="fill-entities-estimate"
                    isOpen={hoverInfo?.date === point.date && hoverInfo?.value === point.estimate}
                    onHover={() => setHoverInfo({ date: point.date, value: point.estimate })}
                    onLeave={() => setHoverInfo(null)}
                    x={x}
                    y={y}
                  />
                  <TimelinePointTooltip
                    key={`point-${point.date}`}
                    content={<CombinedHoverContent costData={point} />}
                    data={{
                      date: point.date,
                      value: point.runningTotal,
                    }}
                    fill="fill-entities-estimate"
                    isOpen={
                      hoverInfo?.date === point.date && hoverInfo?.value === point.runningTotal
                    }
                    onHover={() => setHoverInfo({ date: point.date, value: point.runningTotal })}
                    onLeave={() => setHoverInfo(null)}
                    x={x}
                    y={y}
                  />
                  <TimelinePointTooltip
                    key={`point-${point.date}`}
                    content={<CombinedHoverContent costData={point} />}
                    data={{
                      date: point.date,
                      value: point.budget,
                    }}
                    fill="fill-entities-budget"
                    isOpen={hoverInfo?.date === point.date && hoverInfo?.value === point.budget}
                    onHover={() => setHoverInfo({ date: point.date, value: point.budget })}
                    onLeave={() => setHoverInfo(null)}
                    x={x}
                    y={y}
                  />
                </>
              ))}
          </>
        )}

        {chartType === 'separated' &&
          props.separated.map((projectInfo) => (
            <>
              <TimelinePath<{ runningTotal: number }>
                key={`series-${projectInfo.projectID}-${projectInfo.projectName}`}
                data={projectInfo.separatedCostTimeSeries}
                field="runningTotal"
                stroke="stroke-entities-estimate"
                strokeWidth={1.5}
                x={x}
                y={y}
              />

              {projectInfo.separatedCostTimeSeries.map((point) => (
                <TimelinePointTooltip
                  key={`point-${projectInfo.projectID}-${point.date}`}
                  content={`$${formatCost(point.runningTotal)}`}
                  data={{
                    date: point.date,
                    value: point.runningTotal,
                  }}
                  fill="fill-entities-estimate"
                  isOpen={hoverInfo?.date === point.date && hoverInfo?.value === point.runningTotal}
                  onHover={() => setHoverInfo({ date: point.date, value: point.runningTotal })}
                  onLeave={() => setHoverInfo(null)}
                  x={x}
                  y={y}
                />
              ))}
            </>
          ))}

        <CostFillPattern />
        <CostLabelBlur />
      </SVGWithDimensions>
    </div>
  );
}
