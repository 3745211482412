import { useState } from 'react';

import { useProgramBreakdownQuery } from './hooks/useProgramBreakdownQuery';
import { useProgramQuery } from './hooks/useProgramQuery';
import ProgramDashboard from './ProgramDashboard';
import { useProgramCostTrendlineQuery } from './ProgramDialog/hooks/useProgramCostTrendlineQuery';

type Props = {
  programID: UUID;
};

export default function ProgramDashboardData({ programID }: Props) {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  const { data } = useProgramQuery(programID, {
    onCompleted: () => {
      setSelectedProjectIDs(data?.program?.projects?.map((p) => p.id) ?? []);
    },
  });
  const program = data?.program;

  const { data: breakdownData } = useProgramBreakdownQuery(programID ?? '', selectedProjectIDs);

  const { data: programCostTrendlineData } = useProgramCostTrendlineQuery(
    programID,
    selectedProjectIDs
  );

  return (
    <ProgramDashboard
      chartData={programCostTrendlineData?.programCostTrendline}
      program={program}
      programBreakdown={breakdownData?.programBreakdown?.program ?? []}
      projectBreakdown={breakdownData?.programBreakdown?.projects ?? []}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
      totalProgramCost={breakdownData?.programBreakdown?.total ?? 0}
    />
  );
}
