/* eslint-disable react/jsx-curly-brace-presence */
export default function CAndALegend({ isA }: { isA?: boolean }) {
  const legendEntry = (icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const usedImg = <div className="h-3 w-3 self-center bg-entities-contingencies" />;
  const drawnImg = (
    <div className={`h-3 w-3 self-center`} style={{ background: isA ? '#C0E5FE' : '#92B0FF' }} />
  );
  const pImg1 = (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12">
      <rect fill="url(#pattern0_5867_35069)" height="12" width="12" />
      <defs>
        <pattern
          height="0.233333"
          id="pattern0_5867_35069"
          patternContentUnits="objectBoundingBox"
          width="0.233333"
        >
          <use transform="scale(0.0583333)" xlinkHref="#image0_5867_35069" />
        </pattern>
        <image
          height="4"
          id="image0_5867_35069"
          width="4"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABKADAAQAAAABAAAABAAAAADFbP4CAAAAEklEQVQIHWNg0Oj/z4ABSBIEAOIsBtldqGFqAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
  const pendingImg = <div className="flex gap-1">{pImg1}</div>;
  const remainingImg = (
    <div className={`h-3 w-3 self-center`} style={{ background: isA ? '#2684C8' : '#25469D' }} />
  );
  const overdrawnImg = <div className="h-3 w-3 self-center" style={{ background: '#E11E29' }} />;

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(usedImg, `Used`)}
      {legendEntry(drawnImg, `Drawn`)}
      {legendEntry(pendingImg, `Pending`)}
      {legendEntry(remainingImg, `Remaining`)}
      {legendEntry(overdrawnImg, `Overdrawn`)}
    </div>
  );
}
