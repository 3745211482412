import { useEffectOnce } from 'react-use';

import { INSIGHTS_DEFAULT_MAX_ITEMS_BAR, insightsMaxItemsBar } from '../../api/apollo/reactiveVars';
import useSearchProjectsQueryParams from '../HomeTab/Search/projects/hooks/useSearchProjectsQueryParams';

import { InsightsProject } from './hooks/useInsightsProjectsQuery';
import ProjectsContingenciesAndAllowances from './ProjectsTables/ProjectsContingenciesAndAllowances';
import ProjectsCost from './ProjectsTables/ProjectsCost';
import ProjectsItems from './ProjectsTables/ProjectsItems';
import { InsightsTabId } from './types';

export default function ProjectsListTables({
  projectCounts,
  insightsProjects,
  tabId,
  onFetchMore,
}: {
  projectCounts: { current: number; total: number };
  insightsProjects: InsightsProject[];
  tabId: InsightsTabId;
  onFetchMore: () => void;
}) {
  const params = useSearchProjectsQueryParams();

  useEffectOnce(() => () => {
    insightsMaxItemsBar(INSIGHTS_DEFAULT_MAX_ITEMS_BAR);
  });

  if (!insightsProjects) return null;
  return (
    <>
      {tabId === InsightsTabId.Costs && (
        <ProjectsCost
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
        />
      )}
      {tabId === InsightsTabId.Items && (
        <ProjectsItems
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
        />
      )}
      {tabId === InsightsTabId.Contingencies && (
        <ProjectsContingenciesAndAllowances
          counts={projectCounts}
          loading={false}
          onFetchMore={onFetchMore}
          projects={insightsProjects}
          sortManager={params.sortManager}
        />
      )}
    </>
  );
}
