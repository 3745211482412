import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../../analytics/analyticsEventProperties';
import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Button } from '../../scales';
import ProgramDialog from '../ProgramDialog/ProgramDialog';

export default function NewProgramButton() {
  const hasProgramReportingFeature = useHasFeature(NS_PROGRAM_REPORTING);

  const sendAnalytics = useSendAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  // ToDo: What to do if somebody is not an admin on any projects, and how to determine that?
  if (!hasProgramReportingFeature) return null;

  return (
    <>
      <Button
        endIcon={<Add />}
        label="New Program"
        onClick={() => {
          setIsOpen(true);
          sendAnalytics(ProgramReportingAnalyticsEvent(ProgramReportingTypes.OPEN_PROGRAM_DIALOG));
        }}
        type="secondary"
      />
      {isOpen && (
        <ProgramDialog
          onClose={() => setIsOpen(false)}
          onCompleted={(programID) =>
            navigate(generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }))
          }
        />
      )}
    </>
  );
}
