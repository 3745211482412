import { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';

import { TimelineEvent } from '../../../../analytics/analyticsEventProperties';
import { NULL_ID } from '../../../../constants';
import { unload } from '../../../shared-widgets/TimelineChart/timeline/timeline';
import {
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineSettings,
  TimelineType,
} from '../../../shared-widgets/TimelineChart/timeline/timeline.types';
import {
  getFinalHeight,
  initTimeline,
} from '../../../shared-widgets/TimelineChart/TimelineChartUtils';
import XAxis from '../../Charts/XAxis';

const DEFAULT_MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

type Props = {
  activities?: TimelineActivity[];
  data: TimelineData[];
  items?: TimelineItemData[];
  endDate: string;
  expandedMap: TimelineExpandedMap;
  isPrint?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  maxHeight?: number; // it is used in getFinalHeight
  onAnalytics?: (event: TimelineEvent) => void;
  onExpand: (id: UUID) => void;
  onZoom?: (domain: string[]) => void;
  settings: TimelineSettings;
  startDate: string;
  today?: string;
  width?: number;
  withoutDueDateCount?: number;
  zoomIn?: TimelineData;
  zoomLineCompressed?: boolean;
  zoomLineDisabled?: boolean;
};

export default function TimelineChart(props: Props) {
  const {
    activities = [],
    data: dataOuter = [],
    items = [],
    endDate,
    expandedMap,
    isPrint,
    onAnalytics = () => {},
    onExpand: onExpandOuter,
    onZoom: onZoomOuter = () => {},
    settings,
    startDate,
    today,
    width: widthOuter,
    withoutDueDateCount = 0,
    zoomIn,
    zoomLineCompressed = false,
    zoomLineDisabled = false,
  } = props;
  const timelineRef = createRef<HTMLDivElement>();

  const [data, setData] = useState<TimelineData[]>(dataOuter);

  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  const [width, setWidth] = useState<number | undefined>(widthOuter);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (widthOuter) return;
      const newWidth = timelineRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef, timelineRef.current]);

  useDeepCompareEffect(() => {
    setData(dataOuter);
  }, [dataOuter]);

  const onExpand = ({ id }: TimelineData) => onExpandOuter(id);

  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    onZoomOuter([newMin, newMax]);
  };

  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!width) return;

    setTimeout(() => {
      // Get the scroll container and smoothly scroll down. Top is any large number
      const content = document.getElementById('group-chart-group-container');
      content?.scrollBy({ top: 10000, behavior: 'smooth' });
    }, 1000);

    const chartProps = {
      margin: DEFAULT_MARGIN,
      width,
      withoutDueDateCount,
      height: getFinalHeight(props),
      data,
      items,
      today,
      expandedMap,
      zoomLineCompressed,
      zoomLineDisabled,
      onExpand,
      onZoom,
      onAnalytics,
      isPrint,
      settings,
    };
    setTimelineChart(initTimeline(timelineRef.current, chartProps, activities));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef?.current, width, data]);

  useEffect(() => {
    if (!timelineRef.current) return;
    timelineChart
      ?.expandedMap(expandedMap)
      .height(getFinalHeight(props))
      .render(timelineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [expandedMap]);

  useEffect(() => {
    if (zoomIn) timelineChart?.onZoomIn(zoomIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [zoomIn]);

  useEffect(() => {
    if (startDate && endDate) {
      const d = { end: endDate, id: NULL_ID, name: '', start: startDate, type: TimelineType.PHASE };
      timelineChart?.onZoomInChart(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [startDate, endDate]);

  setTimeout(() => {
    if (startDate && endDate) {
      const d = { end: endDate, id: NULL_ID, name: '', start: startDate, type: TimelineType.PHASE };
      timelineChart?.onZoomInChart(d);
    }
  }, 0);

  useDeepCompareEffect(() => {
    timelineChart?.settings(settings).height(getFinalHeight(props));
  }, [settings]);

  useEffectOnce(() => () => unload());

  return (
    <div className="relative block">
      <XAxis placement="top" totalRange={[props.startDate, props.endDate]} />
      <div ref={timelineRef} className="overflow-hidden" id="timeline-chart" />
    </div>
  );
}
