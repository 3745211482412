import { useCallback, useEffect, useState } from 'react';

//* * Helper hook to provide page loading state for Apollo fetchMore function */
export default function useNextPageLoadingState<T>(
  entries: T[] | undefined
): [boolean, (es: T[] | undefined) => void] {
  const [pageLoading, setPageLoading] = useState(false);
  const [entriesCount, setEntriesCount] = useState(0);

  // Set to false when we received the next page
  useEffect(() => setPageLoading(false), [entries?.length]);
  const onFetchMore = useCallback(
    (entriesLocal: T[] | undefined) => {
      const n = entriesLocal?.length ?? 0;
      if (entriesCount !== n) setEntriesCount(n);
      // Set to false when we hit the last page
      if ((n !== entriesCount) !== pageLoading) setPageLoading(n !== entriesCount);
    },
    [entriesCount, pageLoading]
  );
  return [pageLoading, onFetchMore];
}
