import { PermissionResource } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { SortManager, Table, TableComponents, TextInput, TextLink } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';

import CompanyLogo from './CompanyLogo';
import { CompaniesTableColumns } from './constants';
import useUpsertProjectCompanyRoleMutation from './hooks/useUpsertProjectCompanyRoleMutation';
import { TeamCompany } from './types';

type Props = {
  companies: TeamCompany[];
  projectID: UUID;
  searchTerm: string;
  sortManager: SortManager;
};

export default function CompaniesTable(props: Props) {
  const { canEdit } = usePermissions();
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);

  const [onUpdateProjectCompanyRole] = useUpsertProjectCompanyRoleMutation();

  return (
    <Table
      columnWidths={['150px', '1fr', '1fr', '150px', '1fr']}
      entries={props.companies.map((c) => {
        const id = c.company.id;

        return [
          {
            key: `${id}-${CompaniesTableColumns.LOGO}`,
            component: (
              <TableComponents.Cell className="py-3">
                {c.company.asset && <CompanyLogo asset={c.company.asset} name={c.company.name} />}
              </TableComponents.Cell>
            ),
          },
          {
            key: `${id}-${CompaniesTableColumns.COMPANY}`,
            component: (
              <TableComponents.Cell className="py-3">
                <SearchText searchTerm={props.searchTerm} text={c.company.name} />
              </TableComponents.Cell>
            ),
          },
          {
            key: `${id}-${CompaniesTableColumns.DOMAIN}`,
            component: (
              <TableComponents.Cell className="py-3">
                <SearchText searchTerm={props.searchTerm} text={c.company.domain ?? ''} />
              </TableComponents.Cell>
            ),
          },
          {
            key: `${id}-${CompaniesTableColumns.PEOPLE}`,
            component: (
              <TableComponents.Cell className="py-3">
                <TextLink
                  label={`${c.collaborators.length} People`}
                  to={generateSharedPath(RouteKeys.PROJECT_TEAM_TEAMMATES, {
                    projectId: props.projectID,
                    search: `?search=@${c.company.domain ?? ''}`,
                  })}
                />
              </TableComponents.Cell>
            ),
          },
          {
            key: `${id}-${CompaniesTableColumns.ROLE}`,
            component: (
              <TableComponents.Cell className="py-3">
                {canEditCollaborators ? (
                  <TextInput
                    aria-label="role on project"
                    defaultValue={c.role ?? ''}
                    onBlur={(e) =>
                      onUpdateProjectCompanyRole({
                        variables: {
                          companyID: c.company.id,
                          projectID: props.projectID,
                          role: e.currentTarget.value,
                        },
                      })
                    }
                  />
                ) : (
                  <div className="text-type-primary">{c.role}</div>
                )}
              </TableComponents.Cell>
            ),
          },
        ];
      })}
      headerContent={[
        {
          key: CompaniesTableColumns.LOGO,
        },
        {
          key: CompaniesTableColumns.COMPANY,
          copy: CompaniesTableColumns.COMPANY,
          headerSortKey: CompaniesTableColumns.COMPANY,
        },
        {
          key: CompaniesTableColumns.DOMAIN,
          copy: CompaniesTableColumns.DOMAIN,
          headerSortKey: CompaniesTableColumns.DOMAIN,
          tooltip: "The web domain that identifies users' emails.",
        },
        {
          key: CompaniesTableColumns.PEOPLE,
          copy: CompaniesTableColumns.PEOPLE,
          headerSortKey: CompaniesTableColumns.PEOPLE,
        },

        {
          key: CompaniesTableColumns.ROLE,
          copy: CompaniesTableColumns.ROLE,
          headerSortKey: CompaniesTableColumns.ROLE,
          tooltip:
            "Setting a company's role helps to describe how the company participates on the project.",
        },
      ]}
      sortManager={props.sortManager}
    />
  );
}
