import { Navigate } from 'react-router-dom';

import { YC_INSIGHTS_V2 } from '../../features';
import { InsightsSortKey, PermissionResource, SortDirection } from '../../generated/graphql';
import { INSIGHTS_V2, JOIN_INSIGHTS } from '../../moduleEntitlementFlagsList';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import ExecutiveDashboardV2 from '../ExecutiveDashboardV2/ExecutiveDashboardV2';
import { useHasInsightsProjectsQuery } from '../ExecutiveDashboardV2/hooks/useHasInsightsProjectsQuery';
import HasFeatureFlag from '../HasFeatureFlag';
import HasModuleEntitlement from '../HasModuleEntitlement';
import HasPermission from '../HasPermission';

import ExecutiveDashboard from './ExecutiveDashboard';

export default function ExecutiveDashboardRoute() {
  const companyID = useCompanyTabID() || '';
  const hasInsightsProjects = useHasInsightsProjectsQuery({
    companyID,
    filter: {
      deliveryTypes: [],
      gsfRange: {},
      statuses: [],
      types: [],
      companies: [],
      estimateCostRange: {},
      locations: [],
      orgNodeIDs: [],
      milestoneDesignPhases: [],
      projectLeadIDs: [],
      alertsOnly: false,
    },
    sort: {
      sortKey: InsightsSortKey.STATUS,
      sortDirection: SortDirection.SORT_DESCENDING,
    },
  });

  return hasInsightsProjects ? (
    <HasFeatureFlag
      fallback={
        <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={JOIN_INSIGHTS}>
          <ExecutiveDashboard />
        </HasModuleEntitlement>
      }
      flag={YC_INSIGHTS_V2}
    >
      <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={INSIGHTS_V2}>
        <ExecutiveDashboardV2 />
      </HasModuleEntitlement>
    </HasFeatureFlag>
  ) : (
    <HasPermission
      fallback={<Navigate replace to="/404" />}
      permission={PermissionResource.INSIGHTS_ACCESS}
    >
      <HasFeatureFlag fallback={<ExecutiveDashboard />} flag={YC_INSIGHTS_V2}>
        <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={INSIGHTS_V2}>
          <ExecutiveDashboardV2 />
        </HasModuleEntitlement>
      </HasFeatureFlag>
    </HasPermission>
  );
}
