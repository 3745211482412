import React from 'react';

import ChartsPieGraphGradient from './ChartsPieGraphGradient';
import ChartsPieGraphLabels from './ChartsPieGraphLabels';
import ChartsPieGraphPaths from './ChartsPieGraphPaths';
import { CenterLabel, PieChartData, PieChartOptions, PieChartSize } from './ChartsPieGraphUtils';
import { chartColorUnassigned, pieChartDefaultColors } from './utils';

type Props = {
  centerLabel?: CenterLabel;
  chartSize: PieChartSize;
  colors?: string[];
  data: PieChartData[];
  dataOther?: PieChartData[]; // Represents data that is combined into "Other" entry (data from this is used for tooltips)
  displayLegendTooltip?: boolean;
  displaySectionTooltip?: boolean;
  isCurrency?: boolean;
  labelStyle?: string;
  options?: PieChartOptions;
  onSliceClick?: (name: string) => void;
  title?: string;
  unassignedColor?: string;
};

const PieChart: React.FC<Props> = ({
  centerLabel,
  chartSize,
  colors = pieChartDefaultColors, // Default color scheme for first 20 entries
  data,
  dataOther,
  displayLegendTooltip = false,
  displaySectionTooltip = false,
  isCurrency = false,
  labelStyle,
  onSliceClick,
  options,
  title,
  unassignedColor,
}) => {
  if (!data || data.length === 0) return null;

  const shareSum = data.reduce((acc, curr) => acc + curr.share, 0);
  const { diameter, insideDiameter } = chartSize;
  const nonZeroData = data.filter((item) => item.share > 0);

  const renderCircle = (fill: string) => <circle cx="50%" cy="50%" fill={fill} r={diameter / 2} />;

  const renderInnerCircle = () =>
    insideDiameter &&
    insideDiameter > 0 && (
      <circle className="fill-background-primary" cx="50%" cy="50%" r={insideDiameter / 2} />
    );

  if (shareSum === 0) {
    return (
      <div style={{ position: 'relative' }}>
        <svg height={diameter} width={diameter}>
          {renderCircle(chartColorUnassigned)}
          {renderInnerCircle()}
          <ChartsPieGraphLabels
            centerLabel={centerLabel}
            chartSize={chartSize}
            colors={colors}
            data={nonZeroData}
            displayLegendTooltip={displayLegendTooltip}
            isCurrency={isCurrency}
            labelStyle={labelStyle}
            title={title}
          />
        </svg>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <svg height={diameter} width={diameter}>
        {data.length > 1 ? (
          <ChartsPieGraphPaths
            chartSize={chartSize}
            colors={colors}
            data={nonZeroData}
            dataOther={dataOther}
            displaySectionTooltip={displaySectionTooltip}
            isCurrency={isCurrency}
            onSliceClick={onSliceClick}
            options={options}
          />
        ) : (
          renderCircle(unassignedColor || colors[0])
        )}
        <ChartsPieGraphGradient chartSize={chartSize} options={options} />
        {renderInnerCircle()}
        <ChartsPieGraphLabels
          centerLabel={centerLabel}
          chartSize={chartSize}
          colors={colors}
          data={nonZeroData}
          displayLegendTooltip={displayLegendTooltip}
          isCurrency={isCurrency}
          labelStyle={labelStyle}
          title={title}
        />
      </svg>
    </div>
  );
};

export default PieChart;
