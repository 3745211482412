import { useState } from 'react';

import {
  ProgramCostTrendline,
  ProgramFieldsFragment,
  ProjectCostBreakdown,
} from '../../generated/graphql';
import { chartColors } from '../../theme/colors';
import ProgramCostTrendlineChart from '../dragon-scales/TimelineCharts/ProgramCostTrendline/ProgramCostTrendline';
import { costBreakdownTypeToColorMap } from '../ProjectCostBreakdownReport/utils';

import AllProjectsFilteredOutEmptyState from './AllProjectsFilteredOutEmptyState';
import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardPieChart from './ProgramDashboardPieChart';
import ProgramDashboardProjectSelection from './ProgramDashboardProjectSelection';

type Props = {
  program?: ProgramFieldsFragment;
  programBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  projectBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
  totalProgramCost: number;
  chartData?: ProgramCostTrendline;
};

export default function ProgramDashboard({
  program,
  programBreakdown,
  projectBreakdown,
  selectedProjectIDs,
  setSelectedProjectIDs,
  totalProgramCost,
  chartData,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const projects = program?.projects ?? [];

  const chartColorsReversed = [...chartColors.reverse()];
  const projectColors = new Map<string, string>();
  projectBreakdown.forEach((project, i) => {
    projectColors.set(project.name, chartColorsReversed[i]);
  });

  if (!projects) return null;

  return (
    <div
      className={`flex w-full flex-col justify-center gap-6 p-6 ${
        !selectedProjectIDs.length ? 'h-full' : ''
      }`}
    >
      <div className="flex-grow type-giant">Program Dashboard</div>
      <ProgramDashboardHeader program={program} />
      <ProgramDashboardProjectSelection
        isOpen={isOpen}
        projects={projects}
        selectedProjectIDs={selectedProjectIDs}
        setIsOpen={setIsOpen}
        setSelectedProjectIDs={setSelectedProjectIDs}
      />
      {!selectedProjectIDs.length && (
        <AllProjectsFilteredOutEmptyState
          program={program}
          setSelectedProjectIDs={setSelectedProjectIDs}
        />
      )}
      {!!selectedProjectIDs.length && (
        <>
          <ProgramCostTrendlineChart
            combined={chartData?.combined}
            dataRange={[chartData?.minCost ?? 0, chartData?.maxCost ?? 0]}
            height={500}
            isPrint={false}
            separated={chartData?.separated}
            timeRange={[new Date(chartData?.minDate ?? 0), new Date(chartData?.maxDate ?? 0)]}
          />
          <ProgramDashboardPieChart
            breakdown={projectBreakdown}
            colorMap={projectColors}
            name="Project Breakdown"
            total={totalProgramCost}
          />
          <ProgramDashboardPieChart
            breakdown={programBreakdown}
            colorMap={costBreakdownTypeToColorMap}
            name="Program Breakdown"
            total={totalProgramCost}
          />
        </>
      )}
    </div>
  );
}
