import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

export default function CostYAxis({
  tickFormat,
  tickTotal,
  x,
  y,
  xPosition,
  showHorizontalLines,
}: {
  tickFormat: (value: number) => string | null;
  tickTotal: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  xPosition: Date;
  showHorizontalLines?: boolean;
}) {
  const ticks = y.ticks(tickTotal).map((value) => ({
    value,
    yOffset: y(value),
  }));

  const xOffset = x(xPosition) ?? 0;

  return (
    <svg>
      {ticks.map(({ value, yOffset }) => (
        <g key={`y-tick-${value}`} transform={`translate(${xOffset}, ${yOffset})`}>
          {showHorizontalLines && (
            <line className="stroke-[#e6e6e9]" strokeWidth={2} x2={x.range()[1] - xOffset} />
          )}
          <text
            key={`${value}`}
            className="-translate-x-[75px] translate-y-[5px] text-right text-[15px] font-normal"
          >
            {tickFormat(value)}
          </text>
        </g>
      ))}
    </svg>
  );
}
