import { useState } from 'react';

import { getTodayUtcNoon } from '../../../../utilities/dates';
import { InsightsProject } from '../../hooks/useInsightsProjectsQuery';
import { CHART_HEIGHT, PADDING, TOTAL_HEIGHT } from '../utils';

import InsightsContingencies from './InsightsContingencies';

export default function ProjectContingenciesAndAllowances(props: {
  project: InsightsProject;
  start: Date;
  end: Date;
  hasAllowance?: boolean;
  hasContingency?: boolean;
  today?: Date;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const data = props.project.contingenciesTimeseries;
  const totalRange: [Date, Date] = [props.start, props.end];

  const chart = (
    <InsightsContingencies
      data={data}
      hasAllowance={props.hasAllowance === undefined ? true : props.hasAllowance}
      hasContingency={props.hasContingency === undefined ? true : props.hasContingency}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );

  return (
    <div
      className={`h-[${TOTAL_HEIGHT}px] min-w-full bg-background-primary`}
      style={{ paddingTop: PADDING, paddingBottom: PADDING }}
    >
      {chart}
    </div>
  );
}
