import { FC } from 'react';

import { ProjectCostBreakdownFieldsFragment } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { renderPercentString } from '../../utilities/string';
import { Table } from '../scales';

type Props = {
  costBreakdownTypeToColorMap: Map<string, string>;
  projectCostBreakdown: ProjectCostBreakdownFieldsFragment[];
};

const ProjectCostBreakdownReportTable: FC<Props> = ({
  costBreakdownTypeToColorMap,
  projectCostBreakdown,
}) => {
  const entries = projectCostBreakdown.map(({ name, cost, hasEstimate, percent }) => {
    const color = costBreakdownTypeToColorMap.get(name);
    const costString = hasEstimate ? formatCost(cost, { rounded: true }) : 'No Estimate';
    return [
      {
        component: (
          <div className="flex items-center type-heading3">
            <svg height={28} width={28}>
              <rect
                className={color} // used temporarily until we have chart colors in tailwind
                fill={color} // used by tailwind
                height={12}
                width={12}
                x={8}
                y={8}
              />
            </svg>
            {name?.replace(/([A-Z])/g, ' $1').trim() /* Add spaces between capital letters */}
          </div>
        ),
        key: `${name}-name`,
      },
      {
        component: (
          <div className="type-number">{renderPercentString({ value: percent * 100 })}</div>
        ),
        key: `${name}-percent`,
      },
      {
        component: <div className="type-number">{costString}</div>,
        key: `${name}-percent`,
      },
    ];
  });

  const header = [
    { copy: 'Costs', key: 'header-costs' },
    { copy: 'Percent', key: 'header-percent', rightAligned: true },
    { copy: 'Total', key: 'header-total', rightAligned: true },
  ];

  // Add + 1 for the header
  const tableHeight = 41 * (entries.length + 1);

  return (
    <div className="w-full flex-grow" style={{ height: tableHeight }}>
      <Table columnWidths={['auto', '120px', '120px']} entries={entries} headerContent={header} />
    </div>
  );
};

export default ProjectCostBreakdownReportTable;
