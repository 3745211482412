import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatCost } from '../../../utilities/currency';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { formatNumber } from '../../../utilities/string';
import { SortManager, Table, Tooltip } from '../../scales';
import CostLegend from '../Charts/Legends/CostLegend';
import XAxis from '../Charts/XAxis';
import ZoomTimeline from '../Charts/ZoomTimeline';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import ProjectCost from '../ProjectBabyCharts/ProjectCost';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { generateProjectPath, getProjectsRange } from './utils';

type Props = {
  counts: { current: number; total: number };
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
};

const ProjectsCost: FC<Props> = (props) => {
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const setStartEnd = ([start, end]: string[]) => {
    setStart(new Date(start));
    setEnd(new Date(end));
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: (
      <ZoomTimeline
        onZoom={onZoom}
        projects={props.projects}
        zoom={[start.toISOString(), end.toISOString()]}
      />
    ),
  });

  if (!props.projects) return null;

  const chartsHeader = (
    <div className="w-full">
      <div className="flow-root h-8 w-full items-center">
        <div className="float-left type-table-header">Over timeline</div>
        <div className="float-right -mt-2">
          <CostLegend />
        </div>
      </div>
      <div className="-mb-[9px]">
        <XAxis placement="top" totalRange={[start.toISOString(), end.toISOString()]} />
      </div>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                >
                  <div>
                    <ProjectTableEntry
                      hasAlert={project.activeAlerts.length > 0}
                      project={project}
                      to={generateProjectPath(project.id)}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: <div>{formatCost(project.runningTotal, { short: true })}</div>,
            },
            {
              key,
              centered: true,
              component: (
                <div className="text-entities-milestone">
                  {formatCost(project.budget, { short: true })}
                </div>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div className="text-entities-gap">
                  {formatCost((project.budget || 0) - (project.runningTotal || 0), {
                    short: true,
                  })}
                </div>
              ),
            },
            {
              key,
              component: (
                <ProjectCost
                  end={end}
                  onClick={() => navigate(generateProjectPath(project.id, InsightsTabId.Costs))}
                  project={project}
                  start={start}
                />
              ),
            },
          ];
        })}
        headerContent={[
          {
            component: <div className="h-9" />,
            copy: `Projects (${formatNumber(props.counts.current)} of ${formatNumber(
              props.counts.total
            )})`,
            key: 'projects',
          },
          { copy: 'Running Total', key: 'Running Total' },
          { component: <div className="text-entities-milestone">Budget</div>, key: 'Budget' },
          { component: <div className="text-entities-gap">Gap</div>, key: 'Gap' },
          { component: chartsHeader, key: 'cost' },
        ]}
        loading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
      />
    </>
  );
};

export default ProjectsCost;
