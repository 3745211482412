import { KeyboardEvent } from 'react';

import { SET_PROJECT_LOCATION_PLACEHOLDER } from '../../../../../constants';
import {
  CreateProjectMutationVariables,
  ImageDimension,
  LocationDetailsInput,
  ProjectDeliveryType,
  ProjectType,
} from '../../../../../generated/graphql';
import ProjectDeliveryMethodSelect from '../../../../dragon-scales/ProjectDeliveryMethodSelect/ProjectDeliveryMethodSelect';
import ProjectStatusSelect from '../../../../dragon-scales/ProjectStatusSelect/ProjectStatusSelect';
import ProjectTypeSelect from '../../../../dragon-scales/ProjectTypeSelect/ProjectTypeSelect';
import { Button, Callout, DialogContent, DialogFlowStep, TextInput } from '../../../../scales';
import PlacesAutocompleteWrapper from '../../../../shared-widgets/PlacesAutocomplete/PlacesAutocompleteWrapper';
import Thumbnail from '../../../../shared-widgets/Thumbnail/Thumbnail';

type Props = {
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  project: Partial<CreateProjectMutationVariables>;
  projectDeliveryTypes: ProjectDeliveryType[];
  setProject: (values: Partial<CreateProjectMutationVariables>) => void;
  submitted: boolean;
  statuses: ProjectStatus[];
  template?: ProjectTemplate;
  projectTypes: ProjectType[];
};

export default function getOverviewStep(props: Props): DialogFlowStep {
  const {
    project,
    setProject,
    submitted,
    onKeyDown,
    template,
    projectDeliveryTypes,
    projectTypes,
    statuses,
  } = props;
  const { location, name, statusID, typeID, projectDeliveryTypeID } = project;

  return {
    renderContent: () => (
      <DialogContent>
        <div className="flex h-100 flex-col gap-2">
          {template ? (
            <Callout fullWidth>
              <div className="flex items-center gap-2 p-0.5">
                <Thumbnail
                  dimension={ImageDimension._50}
                  padding={0}
                  size={20}
                  thumbnail={template.project.thumbnail}
                />
                <div className="flex items-center type-body1">{`Creating from ${template.name} template.`}</div>
              </div>
            </Callout>
          ) : (
            <div>Let&#39;s start with some basic information about this new project.</div>
          )}
          <div className="pb-2 pt-3 type-heading3">Project Overview</div>
          <TextInput
            autoFocus
            data-cy="project-name-text-input"
            disabled={submitted}
            label="Project Name *"
            onChange={(value) => {
              setProject({ ...project, name: value });
            }}
            onKeyDown={onKeyDown}
            placeholder={template?.project.name || 'Set project name'}
            value={name}
          />
          <div className="flex flex-row gap-2">
            <ProjectStatusSelect
              entries={statuses}
              isDisabled={submitted}
              label="Project Status *"
              onChange={(status) => setProject({ ...project, statusID: status.id })}
              value={statusID}
            />
            <ProjectTypeSelect
              entries={projectTypes}
              isDisabled={submitted}
              isSearchable
              label="Project Type *"
              onChange={(typeID) => setProject({ ...project, typeID })}
              value={typeID ?? undefined}
            />
          </div>

          <ProjectDeliveryMethodSelect
            entries={projectDeliveryTypes}
            isDisabled={submitted}
            label="Project Delivery Method *"
            onChange={(projectDeliveryType) =>
              setProject({ ...project, projectDeliveryTypeID: projectDeliveryType.id })
            }
            value={projectDeliveryTypeID ?? undefined}
          />
          <PlacesAutocompleteWrapper
            defaultValue={location}
            disabled={submitted}
            label="Location (City) *"
            onChange={(locationDetails?: LocationDetailsInput) => {
              if (locationDetails)
                setProject({
                  ...project,
                  location: locationDetails.name,
                  lat: locationDetails.lat,
                  lon: locationDetails.lon,
                  locationDetails,
                });
            }}
            placeholder={SET_PROJECT_LOCATION_PLACEHOLDER}
          />
        </div>
      </DialogContent>
    ),
    renderFooterLeft: () => null,
    renderFooterRight: ({ onNext }) => (
      <Button
        data-cy="next-button"
        disabled={!name || !statusID || !typeID || !location || !projectDeliveryTypeID}
        label="Next"
        onClick={onNext}
        type="primary"
      />
    ),
  };
}
