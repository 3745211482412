import { renderCostString } from '../../../CostReport/CostReportUtils';
import { CostTimeSeries } from '../InsightsCost/types';

type Props = {
  costData: CostTimeSeries;
};

export default function CombinedHoverContent(props: Props) {
  return (
    <div className="flex w-80 flex-col gap-2">
      <div>{props.costData.date.toLocaleDateString()}</div>
      <div className="flex justify-between">
        <div>Combined Estimate</div>
        <div>{renderCostString({ cost: props.costData.estimate, short: true })}</div>
      </div>
      <div className="flex justify-between">
        <div>Combined Running Total</div>
        <div>{renderCostString({ cost: props.costData.runningTotal, short: true })}</div>
      </div>
      <div className="flex justify-between">
        <div>Potential Upper Range</div>
        <div>
          {renderCostString({ cost: props.costData.pendingMax, short: true, isSigned: true })}
        </div>
      </div>
      <div className="flex justify-between">
        <div>Potential Lower Range</div>
        <div>
          {renderCostString({ cost: props.costData.pendingMin, short: true, isSigned: true })}
        </div>
      </div>
      <div className="flex justify-between">
        <div>Combined Budget</div>
        <div>{renderCostString({ cost: props.costData.budget, short: true })}</div>
      </div>
    </div>
  );
}
