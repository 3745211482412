import { formatCost } from '../../../utilities/currency';
import { StackDataItem } from '../Charts/DesignPhaseStackedBarChart';
import { HeaderDisplayBy } from '../PieCharts/InsightsListHeaderPieBar';

type Props = {
  selectedDisplayBy: HeaderDisplayBy;
  stackData: StackDataItem[];
};

export default function ProjectsSummaryStackedTooltip(props: Props) {
  const sortByCounts = props.selectedDisplayBy === HeaderDisplayBy.COUNT;
  // Extract the projectsCount values and calculate the total
  const dataWithCounts = props.stackData.map((item) => ({
    category: item.category,
    projectsCount: item.values.find((v) => v.name === 'projectsCount')?.value || 0,
  }));
  const totalCount = dataWithCounts.reduce((sum, item) => sum + item.projectsCount, 0);
  const currencyFormat = { short: true };

  return (
    <div className="flex flex-col gap-2">
      <div className="type-heading3">Projects by Design Phase</div>
      <div className="flex flex-col gap-3 text-left">
        {dataWithCounts.map((item) => (
          <div key={item.category}>
            <div className="flex items-center justify-between gap-4">
              <div className="line-clamp-1 flex-1 type-body2">{item.category}</div>
              <div className="w-10 text-right type-body2">
                {sortByCounts ? item.projectsCount : formatCost(item.projectsCount, currencyFormat)}
              </div>
              <div className="w-10 text-right type-body2">
                {calculatePercentage(item.projectsCount, totalCount)}%
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const calculatePercentage = (count: number, totalShare: number): string => {
  if (Number(count) === 0 || Number(totalShare) === 0) {
    return '0';
  }
  const percentage = (100 * count) / totalShare;
  return percentage < 1 ? '<1' : Math.floor(percentage).toString();
};
