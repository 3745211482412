import { useState } from 'react';

import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import InsightsContingencies from '../../dragon-scales/TimelineCharts/InsightsContingencies/InsightsContingencies';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import { InsightsProject } from '../hooks/useInsightsProjectsQuery';
import { getRange } from '../utils';

import { BASE_HEIGHT, CHART_HEIGHT, TOTAL_HEIGHT } from './utils';

export default function ProjectContingenciesAndAllowances(props: {
  project: InsightsProject;
  start: Date;
  end: Date;
  hasAllowance?: boolean;
  hasContingency?: boolean;
  today?: Date;
  onClick?: () => void;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const data = props.project.contingenciesTimeseries;
  const milestones = props.project?.milestones || [];

  const totalRange: [Date, Date] = [props.start, props.end];

  // Draft milestone may have no date a.date.getTime() > 0
  const baseRange = getRange(milestones.filter(isNonNullable).filter((a) => a.date.getTime() > 0));
  const chart = (
    <InsightsContingencies
      data={data}
      hasAllowance={props.hasAllowance === undefined ? true : props.hasAllowance}
      hasContingency={props.hasContingency === undefined ? true : props.hasContingency}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  const base = (
    <InsightsBaseLine
      baseRange={baseRange}
      data={milestones}
      height={BASE_HEIGHT}
      hoverDate={hoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  return (
    <div
      key={props.project.id}
      className={`flex h-[${TOTAL_HEIGHT}px] min-w-full cursor-pointer flex-col bg-background-primary`}
      onClick={props.onClick}
      onKeyPress={props.onClick}
    >
      {chart}
      {base}
    </div>
  );
}
