import { Link } from 'react-router-dom';

import { forecastingEventTypes } from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { FORECASTING_REPORTS_ATTACHED_PROJECT } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../utilities/routes/links';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';

import styles from './AttachedProjectCellStyles';

export default withStyles(styles)(({
  classes,
  report,
}: {
  classes: Classes<typeof styles>;
  report: ForecastingReport;
}) => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();
  return (
    <div className={classes.linkContainer}>
      {report.attachedProject ? (
        <>
          <div className={classes.titleText}>Project</div>
          {report.attachedProject.hasAccess ? (
            <Link
              className={classes.link}
              data-cy={`${FORECASTING_REPORTS_ATTACHED_PROJECT}-${report.name}`}
              onClick={() => {
                sendForecastingAnalytics(forecastingEventTypes.VIEW_PROJECT_CTA, {
                  location: 'reports',
                  projectId: report.attachedProject,
                  reportID: report.id,
                  reportName: report.name,
                });
              }}
              to={generateSharedPath(JoinProjectRoutes.REPORTS, {
                projectId: report.attachedProject.id,
              })}
            >
              {report.attachedProject.name}
            </Link>
          ) : (
            <div className={`${classes.disabledLink}`}>{report.attachedProject.name}</div>
          )}
        </>
      ) : (
        <div data-cy={`${FORECASTING_REPORTS_ATTACHED_PROJECT}-${report.name}`} />
      )}
    </div>
  );
});
