import { useRef } from 'react';
import { useButton, useFocusRing } from 'react-aria';

import { getProjectIdFromUrl } from '../../../utilities/url';
import { useCurrentUser } from '../../contexts/current-user';
import SharedUsersState from '../../dragon-scales/SharedUsersState/SharedUsersState';
import { GroupsIcon } from '../../Icons/GroupsIcon';
import { Chip } from '../../scales';
import CollapseIcon from '../../shared-widgets/CollapseIcon';
import ItemHistoryEventTable from '../ItemHistoryEvent/ItemHistoryEventTable';
import { ItemActivityEvent, getPrivateAndPublicItemHistoryEvents } from '../utils';

type Props = {
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  inPreviewMode: boolean;
  filteredItemEvents: ItemActivityEvent[];
  isPresentationView: boolean;
  isPrivateCollapsed: boolean;
  isPublishEventVisible: boolean;
  item?: ItemDataQueryItem;
  onToggleCollapsePrivate: () => void;
  sharedUsers: Pick<User, 'id' | 'name'>[];
};

const EventsItemHistoryListContent = (props: Props) => {
  const currentUserID = useCurrentUser().id;
  if (!props.item) return <></>;
  const isDraftItemSharedWithUser = props.sharedUsers.some((u) => u.id === currentUserID);
  const showDraftItemHistory =
    isDraftItemSharedWithUser || props.item?.createdBy?.id === currentUserID;
  const { privateEvents, publicEvents } = getPrivateAndPublicItemHistoryEvents(
    props.filteredItemEvents,
    showDraftItemHistory,
    props.isPublishEventVisible
  );
  return (
    <>
      {privateEvents.length > 0 && !props.isPresentationView && !props.inPreviewMode && (
        <EventsItemHistoryListPrivate
          canViewItemAttachments={props.canViewItemAttachments}
          isPresentationView={props.isPresentationView}
          isPrivateCollapsed={props.isPrivateCollapsed}
          item={props.item}
          onToggleCollapsePrivate={props.onToggleCollapsePrivate}
          privateEvents={privateEvents}
          sharedPrivateItemUsers={props.sharedUsers}
          shouldDisplayCosts={props.shouldDisplayCosts}
        />
      )}
      {publicEvents.length > 0 && (
        <EventsItemHistoryListPublic
          canViewItemAttachments={props.canViewItemAttachments}
          isPresentationView={props.isPresentationView}
          item={props.item}
          publicEvents={publicEvents}
          shouldDisplayCosts={props.shouldDisplayCosts}
        />
      )}
    </>
  );
};

export default EventsItemHistoryListContent;

const EventsItemHistoryListHeader = (props: {
  isPrivate: boolean;
  isPresentationView: boolean;
  sharedPrivateItemUsers?: Pick<User, 'id' | 'name'>[];
}) => {
  const privateIcons = (
    <div className="flex items-center">
      <Chip text="Draft" />
      {props.sharedPrivateItemUsers && (
        <div className="flex items-center">
          <SharedUsersState sharedUsers={props.sharedPrivateItemUsers} />
        </div>
      )}
    </div>
  );
  const privateText = props.sharedPrivateItemUsers
    ? 'This history can only be seen by teammates the draft was shared with.'
    : 'Only you can see your private history';

  return (
    <>
      {!props.isPresentationView && (
        <div className="flex flex-grow gap-1">
          {props.isPrivate ? privateIcons : <GroupsIcon />}
          <div className="flex items-center type-body3">
            {props.isPrivate
              ? privateText
              : 'Everyone with access can view the following item history'}
          </div>
        </div>
      )}
    </>
  );
};

const EventsItemHistoryListTable = (props: {
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  events: ItemActivityEvent[];
  item: ItemDataQueryItem;
}) => {
  const projectID = getProjectIdFromUrl();
  const itemLink = {
    id: props.item.id,
    name: props.item.name,
    number: props.item.number,
    status: props.item.status,
    deprecated: false,
  };
  // attach parentItemLink to each event
  const updatedEvents = props.events.map((event) => ({
    ...event,
    itemLinks: {
      ...event.itemLinks,
      item: itemLink,
    },
  }));
  return (
    <div className="pt-2">
      <ItemHistoryEventTable
        canViewItemAttachments={props.canViewItemAttachments}
        events={updatedEvents}
        isDoneFetching
        isItemHistory
        projectID={projectID}
        shouldDisplayCosts={props.shouldDisplayCosts}
      />
    </div>
  );
};

const EventsItemHistoryListPrivate = (props: {
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  isPresentationView: boolean;
  isPrivateCollapsed: boolean;
  item: ItemDataQueryItem;
  privateEvents: ItemActivityEvent[];
  onToggleCollapsePrivate: () => void;
  sharedPrivateItemUsers?: Pick<User, 'id' | 'name'>[];
}) => {
  const ref = useRef(null);
  const { isFocusVisible, focusProps } = useFocusRing();
  const { buttonProps } = useButton({ onPress: props.onToggleCollapsePrivate }, ref);
  return (
    <div className="mb-2 bg-background-1 p-2">
      <button {...buttonProps} {...focusProps} className="flex w-full outline-none">
        <EventsItemHistoryListHeader
          isPresentationView={props.isPresentationView}
          isPrivate
          sharedPrivateItemUsers={props.sharedPrivateItemUsers}
        />
        <CollapseIcon
          className={isFocusVisible ? 'outline' : ''}
          isCollapsed={props.isPrivateCollapsed}
        />
      </button>
      {!props.isPrivateCollapsed && (
        <EventsItemHistoryListTable
          canViewItemAttachments={props.canViewItemAttachments}
          events={props.privateEvents}
          item={props.item}
          shouldDisplayCosts={props.shouldDisplayCosts}
        />
      )}
    </div>
  );
};

const EventsItemHistoryListPublic = (props: {
  shouldDisplayCosts: boolean;
  canViewItemAttachments: boolean;
  isPresentationView: boolean;
  item: ItemDataQueryItem;
  publicEvents: ItemActivityEvent[];
}) => (
  <div className="p-2">
    <EventsItemHistoryListHeader isPresentationView={props.isPresentationView} isPrivate={false} />
    <EventsItemHistoryListTable
      canViewItemAttachments={props.canViewItemAttachments}
      events={props.publicEvents}
      item={props.item}
      shouldDisplayCosts={props.shouldDisplayCosts}
    />
  </div>
);
